import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';

const Login = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [studentId, setStudentId] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleEmailLogin = () => {
        // handle email login logic
    }

    const handleNMDCLogin = () => {
        // handle NMDC login logic
    }

    const activateButton = (buttonType) => {
        setIsActive(buttonType);
    }


    const handleGoogleLogin = async () => {
        // const provider = new firebase.auth.GoogleAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleFacebookLogin = async () => {
        // const provider = new firebase.auth.FacebookAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleTwitterLogin = async () => {
        // const provider = new firebase.auth.TwitterAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleForgotPassword = () => {
        // firebase.auth().sendPasswordResetEmail(email)
        //     .then(() => {
        //         // Handle successful password reset email send
        //     })
        //     .catch((error) => {
        //         // Handle password reset email send error
        //     });
    };


    return (
        // <div style={{
        //     minHeight: '100vh', width: '100vw', backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://i.ytimg.com/vi/91fghY5XvoU/maxresdefault.jpg)",
        //     backgroundSize: 'cover'
        // }}>
        //     <Container className="mt-5 pt-5" >
        //         <Row className="justify-content-md-center">
        //             <Col xs={12} sm={8} md={6} style={{ boxShadow: '0px 0px 10px #0D6EFD', background: 'rgb(247,247,248)', borderRadius: '5px' }} className='p-5 py-4'>
        //                 <div className="text-center mb-4 d-flex gap-1">
        //                     <Button
        //                         className={`col-6 justify-content-center d-flex m-auto`}
        //                         variant={`${!isActive ? 'primary' : 'outline-primary'}`}
        //                         type="submit"
        //                         onClick={() => {
        //                             handleEmailLogin();
        //                             activateButton(false);
        //                         }}
        //                         block>
        //                         Login Form
        //                     </Button>
        //                     <Button
        //                         className={`col-6 justify-content-center d-flex m-auto `}
        //                         variant={`${isActive ? 'primary' : 'outline-primary'}`}
        //                         type="submit"
        //                         onClick={() => {
        //                             handleNMDCLogin();
        //                             activateButton(true);
        //                         }}
        //                         block>
        //                         Sign in with NMDC
        //                     </Button>
        //                 </div>
        //                 <div className='text-center'><h2>{isActive ? "Login With NMDC" : "Login Form"}</h2></div>
        //                 <Form className='pt-2'>
        //                     {isActive ? (
        //                         <>
        //                             <Form.Group controlId="formBasicEmail">
        //                                 <Form.Label>Phone</Form.Label>
        //                                 <Form.Control type="phone" placeholder="Enter phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
        //                                 <Form.Text className="text-muted">
        //                                     We'll never share your email with anyone else.
        //                                 </Form.Text>
        //                             </Form.Group>
        //                             <Form.Group controlId="formBasicEmail">
        //                                 <Form.Label>Student ID</Form.Label>
        //                                 <Form.Control type="number" placeholder="Enter studentId" value={studentId} onChange={(e) => setStudentId(e.target.value)} />
        //                                 <Form.Text className="text-muted">
        //                                     We'll never share your email with anyone else.
        //                                 </Form.Text>
        //                             </Form.Group>
        //                         </>
        //                     ) : (
        //                         <Form.Group controlId="formBasicEmail">
        //                             <Form.Label>Email address</Form.Label>
        //                             <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
        //                             <Form.Text className="text-muted">
        //                                 We'll never share your email with anyone else.
        //                             </Form.Text>
        //                         </Form.Group>
        //                     )}

        //                     <Form.Group controlId="formBasicPassword" className='pt-2'>
        //                         <Form.Label>Password</Form.Label>
        //                         <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
        //                         <Form.Text className="text-right d-flex justify-content-end">
        //                             <a href="#" onClick={handleForgotPassword}>Forgot password?</a>
        //                         </Form.Text>
        //                     </Form.Group>

        //                     {isActive ? (
        //                         <Button className='col-12 mt-3' variant="primary" type="submit" onClick={handleEmailLogin} block>
        //                             Sign in with NMDC
        //                         </Button>
        //                     ) : (
        //                         <Button className='col-12 mt-3' variant="primary" type="submit" onClick={handleEmailLogin} block>
        //                             Sign in with email
        //                         </Button>
        //                     )}


        //                     <div className="text-center  pt-3">
        //                         <span>Or Sign Up with</span>
        //                     </div>

        //                     <div className="text-center mt-3 mb-3">
        //                         <Button className='me-1' variant="danger" type="button" onClick={handleGoogleLogin}>
        //                             <FaGoogle />
        //                         </Button>
        //                         <Button className='me-1' variant="primary" type="button" onClick={handleFacebookLogin}>
        //                             <FaFacebook />
        //                         </Button>
        //                         <Button className='me-1' variant="info" type="button" onClick={handleTwitterLogin}>
        //                             <FaTwitter />
        //                         </Button>
        //                     </div>

        //                     <div className="text-center">
        //                         <span>Don't have an account? </span>
        //                         <a href="/signup">Sign up</a>
        //                     </div>
        //                 </Form>
        //             </Col>
        //         </Row>
        //     </Container >
        // </div>
        <div className="containerstwoFroDevice pt-5 mt-2" id="b-container">

            <form className="formSignIn" id="b-form" method action>
                <div className='d-flex gap-1'>
                    <Button
                        className={`firstLoginForDevice`}
                        variant={`${!isActive ? 'primary' : 'outline-primary'}`}
                        onClick={() => {
                            activateButton(false);
                        }}
                        block>
                        Login Form
                    </Button>
                    <Button
                        className={`secondLoginForDevice`}
                        variant={`${isActive ? 'primary' : 'outline-primary'}`}
                        onClick={() => {
                            activateButton(true);
                        }}
                        block>
                        Sign in with NMDC
                    </Button>
                </div>
                <h2 className="formSignIn_title title">{isActive ? "Login With NMDC" : "Login Form"}</h2>

                <div className="formSignIn__icons">
                    <div className="text-center">
                        <Button className='me-1' variant="danger" type="button" onClick={handleGoogleLogin}>
                            <FaGoogle />
                        </Button>
                        <Button className='me-1' variant="primary" type="button" onClick={handleFacebookLogin}>
                            <FaFacebook />
                        </Button>
                        <Button className='me-1' variant="info" type="button" onClick={handleTwitterLogin}>
                            <FaTwitter />
                        </Button>
                    </div>
                </div>
                <span className="formSignIn__span">or use your email account</span>
                {isActive ? (
                    <>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control className='formSignIn__input' type="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />

                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Control className='formSignIn__input' type="number" placeholder="StudentId" value={studentId} onChange={(e) => setStudentId(e.target.value)} />

                        </Form.Group>
                    </>
                ) : (
                    <Form.Group controlId="formBasicEmail">
                        <Form.Control className='formSignIn__input' type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                    </Form.Group>
                )}

                <Form.Group controlId="formBasicPassword" className='pt-2'>
                    <Form.Control className='formSignIn__input' type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

                    <Form.Text className="text-right d-flex justify-content-end">
                        <a href="#" onClick={handleForgotPassword}>Forgot password?</a>
                    </Form.Text>
                </Form.Group>
                {/* <input className="formSignIn__input" type="text" placeholder="Email" />
                        <input className="formSignIn__input" type="password" placeholder="Password" /><a className="formSignIn__link">Forgot your password?</a> */}

                {/* <button className="formSignIn__button buttonSignIn submit">SIGN IN</button> */}
                {isActive ? (
                    <Button className='col-lg-4 col-8 col-md-4 mt-3 formSignIn__button buttonSignIn ' variant="primary" type="submit" onClick={handleEmailLogin} block>
                        Sign in with NMDC
                    </Button>
                ) : (
                    <Button className='col-md-4 col-8 mt-3 formSignIn__button buttonSignIn ' variant="primary" type="submit" onClick={handleEmailLogin} block>
                        Sign in with email
                    </Button>
                )}
                <div className="text-center pt-2">
                    <span>Don't have an account? </span>
                    <a href="/signup">Sign up</a>
                </div>
            </form>
        </div>
    );
};

export default Login;
