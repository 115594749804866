import React, { useState } from 'react';
import { Form, Button } from 'react-bootstrap';

const ProfileUpdate = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [coverPhoto, setCoverPhoto] = useState(null);

    const handleNameChange = (event) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
    };

    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    const handleCoverPhotoChange = (event) => {
        setCoverPhoto(event.target.files[0]);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Add code to submit form data to server here
    };

    return (
        <Form onSubmit={handleSubmit} className='col-lg-6 pt-5 mt-5 m-auto' style={{ height: '86vh' }}>
            <h3 className='mb-3'>Update Profile</h3>
            <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control type="text" placeholder="Enter your name" value={name} onChange={handleNameChange} />
            </Form.Group>

            <Form.Group controlId="email">
                <Form.Label>Email</Form.Label>
                <Form.Control type="email" placeholder="Enter your email" value={email} onChange={handleEmailChange} />
            </Form.Group>

            <Form.Group controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" placeholder="Enter your password" value={password} onChange={handlePasswordChange} />
            </Form.Group>

            <Form.Group controlId="coverPhoto">
                <Form.Label>Cover Photo</Form.Label>
                <Form.Control type="file" accept="image/*" onChange={handleCoverPhotoChange} />
            </Form.Group>

            <Button className='mt-3 px-3' variant="primary" type="submit">
                Submit
            </Button>
        </Form>
    );
};

export default ProfileUpdate;
