import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  homePageFifthData,
  homePageFourthSectionData,
  homeSectionData,
  testimonialData,
} from "./data";
import "./style.css";
import CountUp from "react-countup";
import Footer from "../Footer/Footer";
import { useGlobalContext } from "../../context";
import { ChevronLeft, ChevronUp, ChevronsUp, Star } from "react-feather";
import Rating from 'react-rating'
import Avatar from "react-avatar";

import { useCallback } from 'react';
import debounce from 'lodash/debounce';

// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import SliderComponent from "./SliderComponent";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import 'swiper/css/pagination';
// import { Navigation, Pagination } from "swiper";
import SwiperCore, { Pagination, Navigation } from 'swiper/core';


const Home = () => {
  const { active, setActive, goToTop, openNav, setOpenNav, isNightMode } =
    useGlobalContext();
  const [books, setBooks] = useState([]);
  const [loadmore, setLoadmore] = useState(false)
  const [data, setData] = useState([])
  // const [page, setPage] = useState(localStorage.getItem('curPageForLoadMore') || 0)
  const [latestData, setLatestData] = useState([])
  console.log(data);
  const [pageForLoadMore, setPageForLoadMore] = useState(localStorage.getItem('curPageForLoadMore'))
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState(1)
  const [showButton, setShowButton] = useState(false);

  const [allAuthors, setAllAuthors] = useState([])

  // ) => {
  //   const storedPage = localStorage.getItem('curPageForLoadMore');
  //   return storedPage ? parseInt(storedPage) : 1;
  // });

  useEffect(() => {
    SwiperCore.use([Pagination, Navigation]);
  }, []);


  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 200) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleButtonClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  console.log(allAuthors)
  console.log(page)





  useEffect(() => {
    fetch(`https://library-b.ethical-digit.com/books?page=0&bookPerPage=15&search=&sort=createdAt&new=true`)
      // fetch(`https://library-b.ethical-digit.com/books`)
      .then(response => response.json())
      .then(res => {
        console.log(res)
        setLatestData([...res?.books])
      })
      .catch(error => console.error(error));
  }, [])

  useEffect(() => {
    // This will run when the page first loads and whenever the title changes
    document.title = "LMS | Home";
    setActive("HOME");
  }, []);



  const handleHomeSecondSectionOnClick = (detail) => {
    console.log(detail);
    localStorage.setItem("curRoute", detail.value);
    setActive(detail.value);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleOurService = (e) => {
    console.log(e.target.value);
    localStorage.setItem("curRoute", e.target.value);
    setActive(e.target.value);
    goToTop();
    setOpenNav(false);
    window.reload();
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 8,
    arrows: true,
    className: "slider",
    // centerMode: true,
    slidesToScroll: 3,
    centerPadding: "60px",
    autoplay: true,
    autoplaySpeed: 3000,
    lazyLoad: true,
    freeMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          initialSlide: 1
        }
      }
    ]
  };

  const params = {
    loop: true,
    slidesPerView: 3,
    spaceBetween: 30,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
  };


  const setting = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false

  };

  useEffect(() => {
    fetch(`https://library-b.ethical-digit.com/authors?authorPerPage=5&sort=popular`)
      .then(response => response.json())
      .then(res => {
        console.log(res)
        setAllAuthors(res?.authors)
        // setLoading(false)
      })
      .catch(error => console.error(error));
  }, [])

  useEffect(() => {
    if (page === 1) {
      console.log('initial request')
      fetch(`https://library-b.ethical-digit.com/books?page=${page - 1}&bookPerPage=16&sort=createdAt`)
        // fetch(`https://library-b.ethical-digit.com/books`)
        .then(response => response.json())
        .then(res => {
          localStorage.setItem('curPageForLoadMore', page);
          // setPage(page + 1)
          setData([...data, ...res?.books])

          // setLoadmore(false)
          setLoading(false)
        })
        .catch(error => console.error(error));
    }
  }, [])

  const loadMoreBooks = () => {
    if (localStorage.getItem('outOfData') == 'false') {
      console.log('i am here')

      setLoadmore(true)
      fetch(`https://library-b.ethical-digit.com/books?page=${localStorage.getItem('curPageForLoadMore')}&bookPerPage=16&sort=createdAt`)
        // fetch(`https://library-b.ethical-digit.com/books`)
        .then(response => response.json())
        .then(res => {
          if (res?.books?.length > 0) {
            const curPage = parseInt(localStorage.getItem('curPageForLoadMore'));
            console.log(curPage)
            localStorage.setItem('curPageForLoadMore', curPage + 1);
            setPage(page + 1)
            setData(prevData => [...prevData, ...res?.books]);
            setLoadmore(false)
          } else {
            setLoadmore(false)
            localStorage.setItem('outOfData', true);
            console.log('res success but out of data')
          }


        })
        .catch(error => console.error(error));
    }

  };



  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
      const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
      const clientHeight = document.documentElement.clientHeight || window.innerHeight;

      console.log(scrollTop + clientHeight >= scrollHeight);
      console.log(scrollTop + clientHeight);
      console.log(scrollHeight);



      if (scrollTop + clientHeight >= scrollHeight) {
        loadMoreBooks();
      } else {
        setLoadmore(false)
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);


  useEffect(() => {
    localStorage.setItem('outOfData', false);
    // const currentScrollPos = localStorage.getItem('currentScrollPos');
    // console.log(currentScrollPos)
    // if (currentScrollPos) {
    console.log(window?.scrollY)
    window.scrollTo(0, 0);
    // }
    window.onload = () => {
      window.scrollTo(0, 0);
    };
  }, [])

  // const goToDetial = () => {
  //   localStorage.setItem('currentScrollPos', window.scrollY);
  // }


  // const handleScroll = useCallback(
  //   debounce(() => {
  //     const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop;
  //     const scrollHeight = (document.documentElement && document.documentElement.scrollHeight) || document.body.scrollHeight;
  //     const clientHeight = document.documentElement.clientHeight || window.innerHeight;

  //     if (scrollTop + clientHeight >= scrollHeight && scrollHeight > clientHeight + 100) {
  //       loadMoreBooks();
  //     }
  //   }, 200),
  //   [loadMoreBooks]
  // );

  // useEffect(() => {
  //   const totalBooks = 32;
  //   const booksPerPage = 16;
  //   const totalPages = Math.ceil(totalBooks / booksPerPage);

  //   if (page > totalPages) {
  //     return;
  //   }

  //   fetch(`https://library-b.ethical-digit.com/books?page=${page - 1}&bookPerPage=16&sort=createdAt`)
  //     .then(response => response.json())
  //     .then(res => {
  //       console.log('res', res)
  //       setData(prevData => [...prevData, ...res?.books]);
  //       setLoadmore(false);
  //       localStorage.setItem('curPageForLoadMore', page);
  //     })
  //     .catch(error => console.error(error));
  // }, [page]);




  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll);

  //   return () => window.removeEventListener('scroll', handleScroll);
  // }, [handleScroll]);


  return (
    <>
      {loading ? (
        <div style={{ width: '100vw', height: '100vh', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

          <img className="pt-4 mt-4" style={{ width: '20vw' }} src="https://cdn.dribbble.com/users/337865/screenshots/3209990/book-loader_v1.4_transp_800x600.gif" alt="" />
        </div>
      ) : (
        <div className={`homePage p-1 ${isNightMode ? 'nightHome' : ''}`} style={{ overflowX: 'hidden', background: '#ECF0F3' }}>
          <div className="pt-5 mt-5" style={{ width: '94vw', margin: 'auto' }} >
            <h4>Latest Books</h4>
            {/* <SliderComponent /> */}
            {latestData?.length > 0 && (
              <Swiper
                slidesPerView={2}
                spaceBetween={10}
                slidesPerGroup={2}
                // loop={true}
                // loopFillGroupWithBlank={true}
                navigation={true}
                modules={[Navigation]}
                // pagination={true}
                pagination={{
                  clickable: true
                }}
                // paginationType="bullets"
                breakpoints={{
                  320: {
                    slidesPerView: 2,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                  },
                  480: {
                    slidesPerView: 4,
                    slidesPerGroup: 2,
                    spaceBetween: 20
                  },
                  830: {
                    slidesPerView: 8,
                    slidesPerGroup: 4,
                    spaceBetween: 5
                  }
                }}
              >
                {latestData?.map(item => (
                  <SwiperSlide key={item._id}>
                    <a href={`/${item?._id}`}>
                      <img style={{ border: '1px solid #0156f4',height: '200px', width: '90%', margin: 'auto', boxShadow: "-9px 6px 16px -6px rgba(45,56,80,0.75)" }} src={`https://ed-nmdc-library.s3.amazonaws.com/pdf/images/${item?.coverPhoto}` || 'https://images.penguinrandomhouse.com/cover/9780446310789'} alt={item?.title} />
                    </a>
                    <p className="pt-1" style={{
                      width: '90%',
                      margin: 'auto',
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textTransform: "capitalize"
                    }}>{item?.title}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            {/* {data?.length > 0 && (
          <Slider
            {...settings}>
            {latestData?.map(book => (
              <div key={book?._id} style={{ boxShadow: "-4px 16px 16px -6px rgba(45,56,80,0.75)", outline: 'none', border: '0' }}>
                <img style={{ border: '1px solid #0156f4', width: '90%', margin: 'auto', boxShadow: "-9px 6px 16px -6px rgba(45,56,80,0.75)" }} src={book?.coverImage || 'https://images.penguinrandomhouse.com/cover/9780446310789'} alt={book?.title} />
                <p className="pt-1" style={{
                  width: '90%',
                  margin: 'auto',
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  textTransform: "capitalize"
                }}>{book?.title}</p>
              </div>
            ))}
          </Slider>
        )} */}


            {/* <Swiper {...params}>
          <SwiperSlide>Slide 1</SwiperSlide>
          <SwiperSlide>Slide 2</SwiperSlide>
          <SwiperSlide>Slide 3</SwiperSlide>
        </Swiper> */}

            {/* <Slider {...settings}>
          {data.map((book) => (
            <div key={book?._id}>
              <img src={book?.coverImage} alt={book?.title} />
              <p>{book?.title}</p>
            </div>
          ))}
        </Slider> */}

          </div>
          <div className={`${isNightMode ? 'nightHome' : ''} d-lg-flex justify-content-center gap-2 pt-lg-5 pt-2 landingHomeParent`} style={{ width: '100vw', minHeight: '100vh',background: '#ECF0F3' }}>
            {/* <div className="col-2" style={{ border: '1px solid red', height: '500px' }}></div> */}
            <div className="col-lg-8 col-12 left_home_template">
              <h1>All Books</h1>
              <div className="d-md-flex flex-wrap p-1 justify-content-center gap-lg-5 py-lg-4 pt-4 pb-0">
                {data?.map((each) => (
                  <div className="col-lg-5 mb-5 mb-lg-0 col-12 position-relative each_children_of_left_home_template" style={{ border: '1px solid #0156f4', borderRadius: '.2rem', boxShadow: "-9px 6px 16px -6px rgba(45,56,80,0.75)", height: '153.66px' }}>
                    <div className="p-1" style={{ marginLeft: '6.5rem' }}>
                      <h5 className="m-0" style={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textTransform: "capitalize"
                      }}>{each?.title}</h5>
                      {/* <p style={{ color: '#83A8C3' }} className="m-0">{each?.publicationDate?.split('T')[0]}, by {each?.author ? each?.author.map(author => author.name).join(', ') : "Not known"}</p> */}
                      <p style={{ color: '#83A8C3' }} className="m-0">{each?.publicationDate}, by {each?.author?.length > 1 ? `${each?.author?.[0]?.name}, ...` : each?.author?.[0]?.name}</p>
                      <ul className='unstyled-list list-inline m-0'>
                        <Rating
                          emptySymbol={<Star size={25} fill='#babfc7' stroke='#babfc7' />}
                          fullSymbol={<Star size={25} fill='#FF9F43' stroke='#FF9F43' />}
                          fractions={2}
                          initialRating={each?.rate}
                          // direction={isRtl ? 'rtl' : 'ltr'}
                          direction='ltr'
                        />
                      </ul>
                      <p style={{ color: '#83A8C3' }} className="m-0">
                        {each?.description.length > 55
                          ? each?.description.slice(0, 55) + "..."
                          : each?.description}
                      </p>
                      {/* <p style={{ color: '#83A8C3' }} className="m-0">A Nobel Prize-winning physicist explains what happened at the very beginning of the...</p> */}
                    </div>
                    <div style={{ position: 'absolute', bottom: '15px', left: '-15px' }}>
                      <a href={`/${each?._id}`}>
                        <img src={`https://ed-nmdc-library.s3.amazonaws.com/pdf/images/${each?.coverPhoto}` || "https://images.penguinrandomhouse.com/cover/9780446310789"} alt="" style={{ borderRadius: '.2rem', border: '1px solid #0156f4', width: '118px', height: '162px' }} />
                      </a>
                    </div>
                  </div>
                ))}


              </div>
              {!loadmore && (
                <div className="d-lg-none d-flex justify-content-center align-items-center m-auto">
                  {localStorage.getItem('outOfData') == 'false' && <button className="p-1 rounded m-auto" onClick={() => loadMoreBooks()}>Load More</button>}
                </div>
              )}
              {loadmore && (
                <div className="loader loader--style5 text-center m-auto w-100" title="4">
                  <span className="me-3">Loading</span>
                  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x={0} y={0}
                    width="24px" height="30px" viewBox="0 0 24 30" style={{ enableBackground: 'new 0 0 50 50' }} xmlSpace="preserve">
                    <rect x={0} y={0} width={4} height={10} fill="#333">
                      <animateTransform attributeType="xml"
                        attributeName="transform" type="translate"
                        values="0 0; 0 20; 0 0"
                        begin="0" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x={10} y={0} width={4} height={10} fill="#333">
                      <animateTransform attributeType="xml"
                        attributeName="transform" type="translate"
                        values="0 0; 0 20; 0 0"
                        begin="0.2s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                    <rect x={20} y={0} width={4} height={10} fill="#333">
                      <animateTransform attributeType="xml"
                        attributeName="transform" type="translate"
                        values="0 0; 0 20; 0 0"
                        begin="0.4s" dur="0.6s" repeatCount="indefinite" />
                    </rect>
                  </svg>
                </div>
              )}

            </div>
            <div className="col-lg-3 col-12 ps-2 py-2 " >
              <h1>Popular Authors</h1>
              <ul className="p-0">
                {allAuthors?.map((author, index) => (
                  <a style={{ textDecoration: 'none', color: 'black' }} href={`/popularAuthors/${author?._id}`}>
                    <li key={index} className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                      <Avatar className='mr-1' src={`https://ed-nmdc-library.s3.amazonaws.com/pdf/author/images/${author?.image}`} size="50" round={true} />
                      <div className="ps-2">
                        <h5 className={`m-0 ${isNightMode && 'text-white'} `}>{author?.name.charAt(0).toUpperCase() + author.name.slice(1)}</h5>
                        <span className={`${isNightMode && 'text-white'}`}>{author?.bookCounts} books</span>
                      </div></li>
                  </a>
                ))}
                {/* <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://img1.wsimg.com/isteam/ip/6a301559-3f6e-4e9e-b9ce-cbd39fef4faa/5652DC43-8AD5-425F-8A4C-61CB1FE209CA.jpeg/:/cr=t:0%25,l:11.85%25,w:80.65%25,h:65.5%25/rs=w:365,h:365,cg:true,m/fx-gs' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">John E. Grayson</h5>
                    <span>320 books</span>
                  </div></li> */}
                {/*
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://images.squarespace-cdn.com/content/v1/53d17c72e4b04908aec6a9d4/1611727944176-R6AZI7BEFEQGR06IZ9XU/Gayle+Laakmann+McDowell' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Gayle Laakmann</h5>
                    <span>190 books</span>
                  </div></li>
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://secure.gravatar.com/avatar/abd40f3372cdfe56cf04b1020f55a319?s=200&d=identicon&r=g' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Manisha Shejwal</h5>
                    <span>280 books</span>
                  </div></li>
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQgjnVhXi7lXr-3kNsYbpn_uYaxYIxYIuQVCA&usqp=CAU' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Eilam, Eldad</h5>
                    <span>120 books</span>
                  </div></li>
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://images.gr-assets.com/authors/1492111911p8/1063732.jpg' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Paula Hawkins</h5>
                    <span>60 books</span>
                  </div></li>
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://i.guim.co.uk/img/media/455a73d1021cede8ba3363ecdd303e484790523c/0_0_3000_1800/master/3000.jpg?width=1200&height=1200&quality=85&auto=format&fit=crop&s=0e1c7524afcb42286d580bcffc475f6b' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Carlos Ruiz Zafón</h5>
                    <span>320 books</span>
                  </div></li>
                <li className="list-unstyled d-flex mb-2 pb-1" style={{ borderBottom: '1px solid #0156f4' }}>
                  <Avatar className='mr-1' src='https://i.pinimg.com/originals/6e/10/cd/6e10cd0b6e157cea8a5b825918ad3bc2.png' size="50" round={true} />
                  <div className="ps-2">
                    <h5 className="m-0">Emily Brontë</h5>
                    <span>280 books</span>
                  </div></li> */}

              </ul>
              <div className="latestNews">
                <h1>Last Journals</h1>
                <div>
                  <img src="https://library.kaasoft.pro/images/post/2017/December/5a2a6de62eebc/small/img-1.jpg" alt="" />
                  <p className="m-0">Dec 04, 2018</p>
                  <a href="/">10 Brilliant Books That Will Unbelievably Change Your Life Forever</a>
                </div>
                <div className="pt-4">
                  <img src="https://library.kaasoft.pro/images/post/2018/December/5c06063468d0f/small/1540217865_1516810438_1600x1200_resize_up_fotolia_19418375_l1.jpg" alt="" />
                  <p className="m-0">Dec 04, 2018</p>
                  <a href="/">A Nobel Prize-winning physicist explains what happened at the very beginning of the...</a>
                </div>
                <div className="pt-4">
                  <img src="https://library.kaasoft.pro/images/post/2017/December/5a2a6de62eebc/small/img-1.jpg" alt="" />
                  <p className="m-0">Dec 04, 2018</p>
                  <a href="/">10 Brilliant Books That Will Unbelievably Change Your Life Forever</a>
                </div>
              </div>

            </div>

          </div>
          {showButton && (
            <button className="go-to-top-button" style={{ position: 'fixed', bottom: 30, right: 30, borderRadius: '50%', background: 'blue', color: 'white' }} onClick={handleButtonClick}>
              <ChevronUp style={{width: '35px', height: '35px'}} />
            </button>
          )}
          {/* <Footer /> */}
        </div >
      )}
    </>


  );
};

export default Home;
