import React from "react";
import "./style.css";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../context";
import { useEffect } from "react";
import { useState } from "react";

const Footer = () => {
  const { active, setActive, goToTop, openNav, setOpenNav } =
    useGlobalContext();
  const [year, setYear] = useState()

  useEffect(() => {
    const currentYear = new Date().getFullYear();
    setYear(currentYear)
  }, [])

  const handleClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleServiceClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  return (
    <div className="footer text-white">
      <div
        style={{
          width: '100vw',
          height: '50px',
          background: "#00142F",
          boxShadow: "white 0px 5px 15px",
        }}
        className="py-3 text-center"
      >
        <h6>© COPYRIGHT {year} ALL RIGHTS RESERVED</h6>
      </div>
    </div>
  );
};

export default Footer;
