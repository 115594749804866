import { useEffect } from "react";

const PreloadPdf = ({ pdfUrls }) => {
    useEffect(() => {
        // Preload each PDF URL
        pdfUrls.forEach((pdfUrl) => {
            const xhr = new XMLHttpRequest();
            xhr.open('GET', pdfUrl, true);
            xhr.send();
        });
    }, [pdfUrls]);

    return null; // Preloading doesn't require any visible content
};

export default PreloadPdf;