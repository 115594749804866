import React from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import './ResearchDetail.css';

const ResearchDetail = () => {
    const researchPaper = {
        id: 1,
        title: "The Impact of Social Media on Mental Health",
        author: "Jane Doe",
        publicationDate: "June 2022",
        abstract:
            "This study investigates the relationship between social media use and mental health, specifically focusing on depression, anxiety, and self-esteem. Using a survey methodology, data was collected from 500 participants aged 18-35. Results indicate that high social media use is positively correlated with depression and anxiety, and negatively correlated with self-esteem.",
        introduction:
            "Social media has become an integral part of our daily lives, providing opportunities for connection, information sharing, and self-expression. However, concerns have been raised about the impact of social media use on mental health, particularly among young adults. This study aims to address this issue by examining the relationship between social media use and mental health.",
        methodology:
            "Participants were recruited through online platforms such as Facebook and Instagram, and were required to complete an online survey. The survey consisted of measures of social media use, depression, anxiety, and self-esteem. Data was analyzed using correlation and regression analyses.",
        results:
            "Results indicate that high social media use is positively correlated with depression and anxiety, and negatively correlated with self-esteem. Specifically, participants who reported spending more than 2 hours per day on social media had significantly higher levels of depression and anxiety, and lower levels of self-esteem. Age and gender did not significantly moderate these relationships.",
        conclusion:
            "This study provides evidence for the negative impact of high social media use on mental health, and highlights the need for further research and interventions to address this issue. Strategies such as social media breaks, mindfulness practices, and digital detoxes may be useful in promoting mental health and reducing the negative effects of social media use.",
        coverPhoto: 'http://cdn.statcdn.com/Infographic/images/normal/19262.jpeg',
        keywords: ["sleep", "memory consolidation", "neuroscience"],
        authors: [
            {
                name: "John Smith",
                affiliation: "University of XYZ"
            },
            {
                name: "Jane Doe",
                affiliation: "University of ABC"
            }
        ]
    };



    return (
        <Container className="my-5 pt-5 researchDetail relative">
            <Row>
                <Col md={4} className="sticky-col">
                    <Card >
                        <Card.Img variant="top" src={researchPaper.coverPhoto} />
                        <Card.Body>
                            <Card.Title>Keywords</Card.Title>
                            <Card.Text className='mb-1'>{researchPaper.keywords.join(', ')}</Card.Text>
                            <Card.Title>Authors</Card.Title>
                            {researchPaper.authors.map((author, index) => (
                                <Card.Text className='mb-1' key={index}>{author.name}</Card.Text>
                            ))}
                            <Card.Title>Affiliations</Card.Title>
                            {researchPaper.authors.map((author, index) => (
                                <Card.Text className='mb-1' key={index}>{author.affiliation}</Card.Text>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
                <Col md={8}>
                    <Card>
                        <Card.Body>
                            <h2>{researchPaper.title}</h2>
                            <Card.Subtitle className="mb-0 text-muted">by {researchPaper.author}</Card.Subtitle>
                            <Card.Text className="text-muted">{researchPaper.publicationDate}</Card.Text>
                            <Card.Title>Abstract</Card.Title>
                            <Card.Text>{researchPaper.abstract}</Card.Text>
                            <Card.Title>Introduction</Card.Title>
                            <Card.Text>{researchPaper.introduction}</Card.Text>
                            <Card.Title>Methodology</Card.Title>
                            <Card.Text>{researchPaper.methodology}</Card.Text>
                            <Card.Title>Results</Card.Title>
                            <Card.Text>{researchPaper.results}</Card.Text>
                            <Card.Title>Conclusion</Card.Title>
                            <Card.Text>{researchPaper.conclusion}</Card.Text>
                            <Button variant="primary">Download Now</Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row >
        </Container >
    );
};

export default ResearchDetail;
