import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
// import firebase from 'firebase/app';
// import 'firebase/auth';
import { FaGoogle, FaFacebook, FaTwitter } from 'react-icons/fa';

const LoginWithNMDC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleEmailLogin = async (event) => {
        event.preventDefault();
        try {
            // await firebase.auth().signInWithEmailAndPassword(email, password);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleGoogleLogin = async () => {
        // const provider = new firebase.auth.GoogleAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleFacebookLogin = async () => {
        // const provider = new firebase.auth.FacebookAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleTwitterLogin = async () => {
        // const provider = new firebase.auth.TwitterAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleForgotPassword = () => {
        // firebase.auth().sendPasswordResetEmail(email)
        //     .then(() => {
        //         // Handle successful password reset email send
        //     })
        //     .catch((error) => {
        //         // Handle password reset email send error
        //     });
    };


    return (
        <div style={{
            minHeight: '100vh', width: '100vw', backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://i.ytimg.com/vi/91fghY5XvoU/maxresdefault.jpg)",
            backgroundSize: 'cover'
        }}>
            <Container className="mt-5 pt-5" >
                <Row className="justify-content-md-center">
                    <Col xs={12} sm={8} md={6} style={{ boxShadow: '0px 0px 10px #0D6EFD', background: 'rgb(247,247,248)', borderRadius: '5px' }} className='p-5'>
                        <div className="text-center mb-4">
                            <h1>Login With NMDC</h1>
                        </div>
                        <Form className='pt-2'>
                            <Form.Group controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" placeholder="Enter email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group controlId="formBasicPassword" className='pt-2'>
                                <Form.Label>Password</Form.Label>
                                <Form.Control type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                <Form.Text className="text-right d-flex justify-content-end">
                                    <a href="#" onClick={handleForgotPassword}>Forgot password?</a>
                                </Form.Text>
                            </Form.Group>

                            <Button className='col-12 mt-3' variant="primary" type="submit" onClick={handleEmailLogin} block>
                                Sign in with email
                            </Button>

                            <div className="text-center  pt-3">
                                <span>Or Sign Up with</span>
                            </div>

                            <div className="text-center mt-3 mb-3">
                                <Button className='me-1' variant="danger" type="button" onClick={handleGoogleLogin}>
                                    <FaGoogle />
                                </Button>
                                <Button className='me-1' variant="primary" type="button" onClick={handleFacebookLogin}>
                                    <FaFacebook />
                                </Button>
                                <Button className='me-1' variant="info" type="button" onClick={handleTwitterLogin}>
                                    <FaTwitter />
                                </Button>
                            </div>

                            <div className="text-center">
                                <span>Don't have an account? </span>
                                <a href="#">Sign up</a>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container >
        </div>
    );
};

export default LoginWithNMDC;
