import { Container, Row, Col } from 'react-bootstrap';
import { useGlobalContext } from '../../context';
import { Link } from 'react-router-dom';
import './Journal.css'
import { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';

const Journals = () => {
    const { isNightMode } = useGlobalContext()
    const [inputValue, setInputValue] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [found_results, setfound_results] = useState()
    // const total = 100; // Replace with your total number of pages
    const maxPagesToShow = 4; // Maximum number of pages to show in the pagination UI

    const [allJournals, setAllJournals] = useState([])
    const [loading, setLoading] = useState(true)

    const [type, setType] = useState('all');

    const actualTotal = Math.ceil(found_results / 15); // Calculate actual total number of pages
    const total = Math.min(actualTotal, 100);

    // const actualTotal = Math.ceil(foundresults / 20); // Calculate actual total number of pages
    // const total = Math.min(actualTotal, 100);

    const handleTypeChange = (event) => {
        setType(event.target.value);
    };


    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
        console.log(pageNumber)
        // Do something with the selected page number
    };
    const renderPageItem = (pageNumber) => (
        <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageClick(pageNumber)}
        >
            {pageNumber}
        </Pagination.Item>
    );

    const renderEllipsisItem = () => (
        <Pagination.Ellipsis key="ellipsis" disabled />
    );
    const paginationItems = [];
    if (total <= maxPagesToShow) {
        for (let i = 1; i <= total; i++) {
            paginationItems.push(renderPageItem(i));
        }
    } else {
        const leftPagesToShow = Math.ceil(maxPagesToShow / 2) - 1;
        const rightPagesToShow = maxPagesToShow - leftPagesToShow - 1;
        let firstPageToShow = currentPage - leftPagesToShow;
        let lastPageToShow = currentPage + rightPagesToShow;

        if (firstPageToShow < 1) {
            lastPageToShow += Math.abs(firstPageToShow) + 1;
            firstPageToShow = 1;
        } else if (lastPageToShow > total) {
            firstPageToShow -= lastPageToShow - total;
            lastPageToShow = total;
        }

        if (firstPageToShow > 1) {
            paginationItems.push(renderPageItem(1));
            if (firstPageToShow > 2) {
                paginationItems.push(renderEllipsisItem());
            }
        }

        for (let i = firstPageToShow; i <= lastPageToShow; i++) {
            paginationItems.push(renderPageItem(i));
        }

        if (lastPageToShow < total) {
            if (lastPageToShow < total - 1) {
                paginationItems.push(renderEllipsisItem());
            }
            paginationItems.push(renderPageItem(total));
        }
    }

    const handleInputChange = (event) => {
        setInputValue(event.target.value);
    };

    useEffect(() => {
        document.title = "LMS | Journals";
    }, []);

    // const journals = [
    //     {
    //         title: 'Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University',
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         journal: 'Category 1',
    //         date: "August 31, 2018",
    //         volume: '1',
    //         issue: "11",
    //         doi: '10.4236/ce.2018.911122',
    //         downloads: 100,
    //         views: 200,
    //         citations: 50,
    //     },
    //     {
    //         title: 'The Role of Branding on Educational Performance in the Egyptian Private Universities',
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         journal: 'Category 2',
    //         date: "August 31, 2018",
    //         volume: '2',
    //         issue: "11",
    //         doi: '10.4236/ce.2018.911122',
    //         downloads: 150,
    //         views: 250,
    //         citations: 75,
    //     },
    //     {
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         title: "Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University",
    //         journal: "Creative Education",
    //         volume: "9",
    //         issue: "11",
    //         date: "August 31, 2018",
    //         doi: "10.4236/ce.2018.911122",
    //         downloads: 708,
    //         views: 1462,
    //         citations: 1,
    //     },
    //     {
    //         authors: "Amel Rashwan, Ayman Shawky, Mohamed A. Ragheb, Alaa A. Bary",
    //         title: "The Role of Branding on Educational Performance in the Egyptian Private Universities",
    //         journal: "Open Access Library Journal",
    //         volume: "5",
    //         issue: "10",
    //         date: "October 15, 2018",
    //         doi: "10.4236/oalib.1104882",
    //         downloads: 699,
    //         views: 2566,
    //         citations: 1,
    //     },
    //     {
    //         authors: "Karen E. Boden",
    //         title: "Pedagogical Innovation among University Faculty",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "5",
    //         date: "May 14, 2019",
    //         doi: "10.4236/ce.2019.105063",
    //         downloads: 860,
    //         views: 2260,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Zhiqiang Zhou, Li Guo",
    //         title: "The Teaching and Learning Status of Chemical Safety in Chinese Universities and Reform Suggestions on It",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "10",
    //         date: "October 14, 2019",
    //         doi: "10.4236/ce.2019.1010154",
    //         downloads: 843,
    //         views: 1352,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Qiangqiang Zhou",
    //         title: "Research on Talent Cultivation Strategy of Adult Universities under the Background of “Mass Entrepreneurship and Innovation”",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "13",
    //         date: "December 20, 2019",
    //         doi: "10.4236/ce.2019.1013261",
    //         downloads: 550,
    //         views: 1187,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Jiemin Liang, Xinfeng Zhang, Juan Wang, Liu Feng, Chao Xu, Kun Cheng, Guoguang Cao, Dongmei Yan, Bo Liu",
    //         title: "Mental Health Status of College Freshmen and Influencing Factors",
    //         journal: "Psychology",
    //         volume: "11",
    //         issue: "5",
    //         date: "May 28, 2020",
    //         doi: "10.4236/psych.2020.115050",
    //         downloads: 530,
    //         views: 2059,
    //         citations: 0,
    //     },
    //     {
    //         title: 'Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University',
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         journal: 'Category 1',
    //         date: "August 31, 2018",
    //         volume: '1',
    //         issue: "11",
    //         doi: '10.4236/ce.2018.911122',
    //         downloads: 100,
    //         views: 200,
    //         citations: 50,
    //     },
    //     {
    //         title: 'The Role of Branding on Educational Performance in the Egyptian Private Universities',
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         journal: 'Category 2',
    //         date: "August 31, 2018",
    //         volume: '2',
    //         issue: "11",
    //         doi: '10.4236/ce.2018.911122',
    //         downloads: 150,
    //         views: 250,
    //         citations: 75,
    //     },
    //     {
    //         authors: "Aly S. Gohar, Wael W. Radwan, Hosam I. Morad",
    //         title: "Hindrances of International Publication of Egyptian Educational Researches from the Staff Members’ Point of View at Damietta University",
    //         journal: "Creative Education",
    //         volume: "9",
    //         issue: "11",
    //         date: "August 31, 2018",
    //         doi: "10.4236/ce.2018.911122",
    //         downloads: 708,
    //         views: 1462,
    //         citations: 1,
    //     },
    //     {
    //         authors: "Amel Rashwan, Ayman Shawky, Mohamed A. Ragheb, Alaa A. Bary",
    //         title: "The Role of Branding on Educational Performance in the Egyptian Private Universities",
    //         journal: "Open Access Library Journal",
    //         volume: "5",
    //         issue: "10",
    //         date: "October 15, 2018",
    //         doi: "10.4236/oalib.1104882",
    //         downloads: 699,
    //         views: 2566,
    //         citations: 1,
    //     },
    //     {
    //         authors: "Karen E. Boden",
    //         title: "Pedagogical Innovation among University Faculty",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "5",
    //         date: "May 14, 2019",
    //         doi: "10.4236/ce.2019.105063",
    //         downloads: 860,
    //         views: 2260,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Zhiqiang Zhou, Li Guo",
    //         title: "The Teaching and Learning Status of Chemical Safety in Chinese Universities and Reform Suggestions on It",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "10",
    //         date: "October 14, 2019",
    //         doi: "10.4236/ce.2019.1010154",
    //         downloads: 843,
    //         views: 1352,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Qiangqiang Zhou",
    //         title: "Research on Talent Cultivation Strategy of Adult Universities under the Background of “Mass Entrepreneurship and Innovation”",
    //         journal: "Creative Education",
    //         volume: "10",
    //         issue: "13",
    //         date: "December 20, 2019",
    //         doi: "10.4236/ce.2019.1013261",
    //         downloads: 550,
    //         views: 1187,
    //         citations: 0,
    //     },
    //     {
    //         authors: "Jiemin Liang, Xinfeng Zhang, Juan Wang, Liu Feng, Chao Xu, Kun Cheng, Guoguang Cao, Dongmei Yan, Bo Liu",
    //         title: "Mental Health Status of College Freshmen and Influencing Factors",
    //         journal: "Psychology",
    //         volume: "11",
    //         issue: "5",
    //         date: "May 28, 2020",
    //         doi: "10.4236/psych.2020.115050",
    //         downloads: 530,
    //         views: 2059,
    //         citations: 0,
    //     },
    //     // Add more journals here
    // ];

    useEffect(() => {
        setLoading(true)
        fetch(`https://library-b.ethical-digit.com/research_journals?page=${currentPage - 1}&journalPerPage=15&search=${inputValue}&sort=createdAt&type=${type}`)
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                console.log('res', res)
                setAllJournals([...res?.researchJournals])
                setfound_results(res?.found_results)
                setLoading(false)
            })
            .catch(error => console.error(error));
    }, [type, currentPage])

    const handleSubmit = (event) => {
        event.preventDefault();
        setLoading(true)
        fetch(`https://library-b.ethical-digit.com/research_journals?page=0&bookPerPage=15&search=${inputValue}&sort=createdAt`)
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setAllJournals([...res?.researchJournals])
                setLoading(false)
            })
            .catch(error => console.error(error));
        // setInputValue('');
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <img className="pt-4 mt-4" style={{ width: '20vw' }} src="https://cdn.dribbble.com/users/337865/screenshots/3209990/book-loader_v1.4_transp_800x600.gif" alt="" />
                </div>) : (
                <div style={{background: '#ECF0F3'}} className={` ${isNightMode ? 'nightHome journal' : 'lightJournal'}`}>
                    <Container className="mt-5 pt-lg-5 pt-2 relative">
                        <div className='d-lg-flex gap-3 journallistframe'>
                            {/* <h4 className='mb-4'>Subjects and fields</h4> */}
                            <div className='p-3 rounded mt-md-0 col-md-3 border' style={{ background: '#FFFFFF', height: '600px', position: 'sticky', top: '80px' }}>

                                <h5>Browse Subjects</h5>
                                <div className='mb-2' style={{ background: 'red', border: '2px solid red' }}></div>
                                <ul className='list-unstyled'>
                                    <li>
                                        <Link to='#'>Biomedical &amp; Life Sci.</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Business &amp; Economics</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Chemistry &amp; Materials Sci.</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Computer Sci. &amp; Commun.</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Earth &amp; Environmental Sci.</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Engineering</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Medicine &amp; Healthcare</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Physics &amp; Mathematics</Link>
                                    </li>

                                    <li>
                                        <Link to='#'>Agriculture &amp; Food Science</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Arts &amp; Literature</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Education &amp; Language</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Energy  &amp; Power</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Law  &amp; Politics</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Philosophy  &amp; Religion</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Psychology  &amp; Behavioral Science</Link>
                                    </li>
                                    <li>
                                        <Link to='#'>Sports  &amp; Leisure</Link>
                                    </li>
                                </ul>


                            </div>
                            <div className='mt-md-0 mt-4 ' >
                                <div className=' p-3 border mb-3 rounded' style={{ background: '#FFFFFF', position: 'sticky', top: '80px', boxShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}>
                                    <form className='d-flex' onSubmit={handleSubmit}>
                                        <input type="text" className='p-1 rounded' style={{ width: '90%' }} value={inputValue} onChange={handleInputChange} />
                                        <button className='p-1 rounded px-2' type="submit">Search</button>
                                    </form>
                                    {/* <div className='my-1 pt-2'>
                                        <input type='checkbox' className='me-2' id='local' name='local' />
                                        <label className='me-2' htmlFor='local'>Local</label>
                                        <input className='me-2' type='checkbox' id='international' name='international' />
                                        <label className='me-2' htmlFor='international'>International</label>
                                        <input className='me-2' type='checkbox' id='all' name='all' />
                                        <label htmlFor='all'>All</label>
                                    </div> */}
                                    <div className='my-1 pt-2'>
                                    <label htmlFor='all' className='me-2'>
                                            <input type='radio' className='me-2' id='all' name='type' value='all' checked={type === 'all'} onChange={handleTypeChange} />
                                            All
                                        </label>
                                        <label className='me-2' htmlFor='local'>
                                            <input type='radio' className='me-2' id='local' name='type' value='local' checked={type === 'local'} onChange={handleTypeChange} />
                                            Local
                                        </label>
                                        <label className='me-2' htmlFor='international'>
                                            <input type='radio' className='me-2' id='international' name='type' value='international' checked={type === 'international'} onChange={handleTypeChange} />
                                            International
                                        </label>
                                        
                                    </div>
                                </div>
                                <div className=' p-3 border rounded' style={{ background: '#FFFFFF' }}>
                                    {allJournals?.map((journal, index) => (
                                        <div className='mt-2 mb-3 border-bottom' key={index} >
                                            <Link to={`/journals/${journal?._id}`} className='text-decoration-none'>
                                                <h5 className='mb-1 font-weight-bold' style={{ color: 'black', textTransform: 'capitalize' }}>{journal?.title?.toLowerCase()}</h5>
                                            </Link>
                                            <p className='mb-0' style={{ color: '#0B4FA7' }}>{journal?.author.map(author => {
                                                const nameParts = author?.split(' ');
                                                const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
                                                return capitalizedParts.join(' ');
                                            }).join(", ")}</p>
                                            <p className='mb-0' style={{ color: '#0B4FA7' }}>{journal?.journal}  Vol - {journal?.volume}</p>
                                            <p className='mb-0' style={{ color: '#0B4FA7' }}>DOI: {journal?.doi} Downloads: {journal?.downloads?.length} - Views: {journal?.read?.length} - Citations: 0</p>
                                        </div>
                                    ))}
                                </div>
                                <div className='pt-3'>
                                    <Pagination className='justify-content-center'>
                                        <Pagination.First onClick={() => handlePageClick(1)} />
                                        <Pagination.Prev
                                            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                                        />
                                        {paginationItems}
                                        <Pagination.Next
                                            onClick={() => handlePageClick(Math.min(currentPage + 1, total))}
                                        />
                                        <Pagination.Last onClick={() => handlePageClick(total)} />
                                    </Pagination>
                                </div>
                            </div>

                        </div>
                    </Container>
                </div>
            )}
        </>
    );
};

export default Journals;



// import { useEffect, useState } from 'react';
// import { Container, Row, Col, Card } from 'react-bootstrap';
// import { useGlobalContext } from '../../context';
// import './Journal.css'

// const Journals = () => {
//     const { isNightMode } = useGlobalContext()
//     const [journals, setJournals] = useState([]);

//     useEffect(() => {
//         // Fetch journals data from API or local storage
//         const fetchedJournals = [
//             {
//                 id: 1,
//                 title: 'Journal of Science',
//                 editor: 'John Smith',
//                 publisher: 'Wiley',
//                 publicationDate: 'January 2022',
//                 description: 'This journal features articles on the latest research in science, including studies on physics, chemistry, and biology.'
//             },
//             {
//                 id: 2,
//                 title: 'Journal of Education',
//                 editor: 'Jane Doe',
//                 publisher: 'Taylor & Francis',
//                 publicationDate: 'February 2022',
//                 description: 'This journal features articles on the latest research in education, including studies on teaching strategies, student learning, and curriculum development.'
//             },
//             {
//                 id: 3,
//                 title: 'Journal of Economics',
//                 editor: 'Bob Johnson',
//                 publisher: 'Oxford University Press',
//                 publicationDate: 'March 2022',
//                 description: 'This journal features articles on the latest research in economics, including studies on microeconomics, macroeconomics, and econometrics.'
//             },
//             {
//                 id: 4,
//                 title: 'Journal of Engineering',
//                 editor: 'Karen Davis',
//                 publisher: 'IEEE',
//                 publicationDate: 'April 2022',
//                 description: 'This journal features articles on the latest research in engineering, including studies on civil engineering, mechanical engineering, and electrical engineering.'
//             },
//             {
//                 id: 5,
//                 title: 'Journal of Law',
//                 editor: 'Samuel Lee',
//                 publisher: 'Cambridge University Press',
//                 publicationDate: 'May 2022',
//                 description: 'This journal features articles on the latest research in law, including studies on international law, criminal law, and civil law.'
//             },
//             {
//                 id: 6,
//                 title: 'Journal of Sociology',
//                 editor: 'Olivia Smith',
//                 publisher: 'SAGE Publications',
//                 publicationDate: 'June 2022',
//                 description: 'This journal features articles on the latest research in sociology, including studies on social structures, social inequality, and social change.'
//             },
//             {
//                 id: 7,
//                 title: 'Journal of Political Science',
//                 editor: 'Henry Wilson',
//                 publisher: 'MIT Press',
//                 publicationDate: 'July 2022',
//                 description: 'This journal features articles on the latest research in political science, including studies on government, politics, and public policy.'
//             },
//             {
//                 id: 8,
//                 title: 'Journal of History',
//                 editor: 'Emma Thompson',
//                 publisher: 'University of Chicago Press',
//                 publicationDate: 'August 2022',
//                 description: 'This journal features articles on the latest research in history, including studies on world history, cultural history, and social history.'
//             },
//             {
//                 id: 9,
//                 title: 'Journal of Linguistics',
//                 editor: 'David Kim',
//                 publisher: 'John Benjamins Publishing Company',
//                 publicationDate: 'September 2022',
//                 description: 'This journal features articles on the latest research in linguistics, including studies on language acquisition, language processing, and language use.'
//             },
//             {
//                 id: 10,
//                 title: 'Journal of Philosophy',
//                 editor: 'Sophie Brown',
//                 publisher: 'Routledge',
//                 publicationDate: 'October 2022',
//                 description: 'This journal features articles on the latest research in philosophy, including studies on metaphysics, ethics, and epistemology.'
//             },
//             {
//                 id: 21,
//                 title: 'Journal of Neuroscience',
//                 editor: 'Mark Johnson',
//                 publisher: 'MIT Press',
//                 publicationDate: 'March 2022',
//                 description: 'This journal features articles on the latest research in neuroscience, including studies on the brain, nervous system, and behavior.'
//             },
//             {
//                 id: 22,
//                 title: 'Journal of Sociology',
//                 editor: 'Amy Lee',
//                 publisher: 'Routledge',
//                 publicationDate: 'April 2022',
//                 description: 'This journal features articles on the latest research in sociology, including studies on social structure, culture, and inequality.'
//             },
//             {
//                 id: 23,
//                 title: 'Journal of Education',
//                 editor: 'David Kim',
//                 publisher: 'Wiley',
//                 publicationDate: 'May 2022',
//                 description: 'This journal features articles on the latest research in education, including studies on teaching methods, student learning, and educational policy.'
//             },
//             {
//                 id: 24,
//                 title: 'Journal of Economics',
//                 editor: 'Karen Lee',
//                 publisher: 'Cambridge University Press',
//                 publicationDate: 'June 2022',
//                 description: 'This journal features articles on the latest research in economics, including studies on markets, trade, and economic growth.'
//             },
//             {
//                 id: 25,
//                 title: 'Journal of Linguistics',
//                 editor: 'James Lee',
//                 publisher: 'Oxford University Press',
//                 publicationDate: 'July 2022',
//                 description: 'This journal features articles on the latest research in linguistics, including studies on language acquisition, syntax, and semantics.'
//             },
//             {
//                 id: 26,
//                 title: 'Journal of Philosophy',
//                 editor: 'Rachel Kim',
//                 publisher: 'Taylor & Francis',
//                 publicationDate: 'August 2022',
//                 description: 'This journal features articles on the latest research in philosophy, including studies on ethics, metaphysics, and epistemology.'
//             },
//             {
//                 id: 27,
//                 title: 'Journal of Environmental Science',
//                 editor: 'Michael Lee',
//                 publisher: 'Elsevier',
//                 publicationDate: 'September 2022',
//                 description: 'This journal features articles on the latest research in environmental science, including studies on climate change, pollution, and conservation.'
//             },
//             {
//                 id: 28,
//                 title: 'Journal of Political Science',
//                 editor: 'Sarah Kim',
//                 publisher: 'SAGE Publications',
//                 publicationDate: 'October 2022',
//                 description: 'This journal features articles on the latest research in political science, including studies on governance, public policy, and international relations.'
//             },
//             {
//                 id: 29,
//                 title: 'Journal of History',
//                 editor: 'Thomas Lee',
//                 publisher: 'Cambridge University Press',
//                 publicationDate: 'November 2022',
//                 description: 'This journal features articles on the latest research in history, including studies on the past, historical events, and their impact on the present.'
//             },
//             {
//                 id: 30,
//                 title: 'Journal of Literature',
//                 editor: 'Emily Kim',
//                 publisher: 'Johns Hopkins University Press',
//                 publicationDate: 'December 2022',
//                 description: 'This journal features articles on the latest research in literature, including studies on literary theory, criticism, and analysis of literary works.'
//             },
//         ]

//         setJournals(fetchedJournals);
//     }, []);

//     useEffect(() => {
//         // This will run when the page first loads and whenever the title changes
//         document.title = "LMS | Journals";
//     }, []);

//     return (
//         <div className={` ${isNightMode ? 'nightHome' : ''}`}>
//             <Container className="mt-4 pt-5">
//                 <Row>
//                     {journals.map((journal) => (
//                         <Col key={journal.id} xs={12} md={6} lg={4} className="my-3">
//                             <Card className="h-100">
//                                 <Card.Body className={`${isNightMode ? 'journalEachPaper' : ''}`}>
//                                     <Card.Title>{journal.title}</Card.Title>
//                                     <Card.Subtitle className="mb-2 text-muted">{journal.editor}</Card.Subtitle>
//                                     <Card.Text className="text-muted">{journal.publisher} - {journal.publicationDate}</Card.Text>
//                                     <Card.Text>{journal.description}</Card.Text>
//                                 </Card.Body>
//                             </Card>
//                         </Col>
//                     ))}
//                 </Row>
//             </Container>
//         </div>
//     );
// };

// export default Journals;
