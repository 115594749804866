import React, { useEffect } from "react";
import { useState } from "react";
// import { Dropdown, Image } from "react-bootstrap";
import { CreditCard, Globe, Lock, LogIn, Moon, Power, Search, Server, Sun } from "react-feather";
import { Link, redirect } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import { useGlobalContext } from "../../context";
import "./style.css";
import avatar from './avatar.webp';
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap'
import Avatar from "react-avatar";
import { Image } from "react-bootstrap";

const Navbar = () => {
  const { active, setActive, goToTop, openNav, setOpenNav, isNightMode, setIsNightMode } =
    useGlobalContext();
  const [hover, setHover] = useState(false);
  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [link, setLink] = useState("/login");
  const [innedrWith, setInnerWidth] = useState()
  // const history = useHistory();


  // useEffect(() => {
  const updateLink = () => {
    const isMobile = innedrWith <= 500;
    const link = isMobile ? "/login" : "/logintest";
    setLink(link);
  };

  useEffect(() => {
    updateLink();
  }, [])


  const handleNightModeToggle = () => {
    setIsNightMode(!isNightMode);
    localStorage.setItem("nightmode", JSON.stringify({ nightMode: isNightMode ? false : true }))

  }

  const handleProfileDropdownToggle = () => setShowProfileDropdown(!showProfileDropdown);

  console.log(hover);

  console.log(window.location.href.split("/")[3]);

  const handleClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleServiceClick = (e) => {
    localStorage.setItem("curRoute", e.target.innerText);
    setActive(e.target.innerText);
    goToTop();
    setOpenNav(false);
    window.reload();
  };
  const handleLogoClick = () => {
    localStorage.setItem("curRoute", "HOME");
    setActive("HOME");
    goToTop();
    setOpenNav(false);
    window.reload();
  };

  const clickNavOpne = () => {
    setOpenNav(!openNav);
  };

  return (
    <div>
      <div style={{background: '#ECF0F3'}} className={`navbar d-flex justify-content-center align-items-center py-0 px-lg-5 ${isNightMode ? 'night-mode' : ''}`}>
        <div className="col-lg-4 text-info text-start">
          <Link className="text-decoration-none" to="/" onClick={handleLogoClick}>
            <img
              style={{ width: "90px", height: '70px', objectFit: 'contain' }}
              src="/library/logo.png"
              alt="logo"
            />
            <span className="text-decoration-none logoText" style={{color: '#2E69BB', fontWeight: 'bold', fontSize: '1.2rem'}}>NMDC E-Library</span>
          </Link>
        </div>
        <div
          className="menuIcon d-lg-none col-7 text-end"
          style={{}}
        >
          {isNightMode ? <Sun size={23} className="me-3 mb-2 menuIcon" onClick={handleNightModeToggle} /> : <Moon className="me-3 mb-2" size={23} onClick={handleNightModeToggle} />}
          <i
            style={{ fontSize: "1.2rem", color: `${isNightMode ? 'white' : 'black'}` }}
            onClick={clickNavOpne}
            className="fa-sharp fa-solid fa-bars pe-3"
          ></i>
        </div>

        <UncontrolledDropdown style={{ listStyleType: 'none' }} tag='li' className={`dropdown-user-device nav-item `}>
          <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>

            <Image src={avatar} alt="Avatar" className="mr-2" width={40} height={40} roundedCircle border="1px solid #ddd"
              onClick={handleProfileDropdownToggle}
              onMouseEnter={() => setShowProfileDropdown(true)}
              onMouseLeave={() => setShowProfileDropdown(false)}
            />
          </DropdownToggle>
          <DropdownMenu right className={`${isNightMode ? 'night-mode' : ''}`} style={{ width: '200px', marginTop: '13px' }}>
            <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/updateProfile'>
              <CreditCard size={19} className='me-3' />
              <span className='align-middle'
              >Edit Profile</span>
            </DropdownItem>
            <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/login'
            >
              <Server size={19} className='me-3' />
              <span className='align-middle'>Favourite</span>
            </DropdownItem>
            <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/login'
            >
              <LogIn size={19} className='me-3' />
              <span className='align-middle'>Log In</span>
            </DropdownItem>
            <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/login'
            >
              <Power size={19} className='me-3' />
              <span className='align-middle'>Logout</span>
            </DropdownItem>

          </DropdownMenu>
        </UncontrolledDropdown>
        <div className={`col-lg-2 menulistparent ${openNav && "activeNavbar"}`}>
          <ul className="d-lg-flex justify-content-around align-items-center mb-0">
            <li
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
              className="dropBtn list-unstyled"
            >
              <span
                className={`${active === "Myanmar" ? "activetext" : (active === "English" && 'activetext')} `}
              >
                Books{" "}
                <span>
                  {/* <i class="fa-solid fa-caret-down"></i> */}
                  {hover ? (
                    <i
                      style={{ fontSize: ".7rem" }}
                      class="fa-solid fa-chevron-up border"
                    ></i>
                  ) : (
                    <i
                      style={{ fontSize: ".7rem" }}
                      id="downArrow"
                      class="fa-solid fa-chevron-down downArrow border"
                    ></i>
                  )}
                </span>
              </span>

              <div style={{background: '#ECF0F3'}} className={`dropDownlist ${isNightMode ? 'night-mode' : ''}`}>
                <p className="py-1 px-2">
                  <Link
                    className={`${active === "Myanmar"
                      ? "text-decoration-none activetext"
                      : `text-decoration-none ${isNightMode ? 'text-white' : 'text-black'}`
                      }`}
                    to="/myanmar_books"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    Myanmar
                  </Link>
                </p>
                <p className="py-1 px-2">
                  <Link
                    className={`${active === "English"
                      ? "text-decoration-none activetext"
                      : `text-decoration-none ${isNightMode ? 'text-white' : 'text-black'}`
                      }`}
                    to="/english_books"
                    onClick={(e) => handleServiceClick(e)}
                  >
                    English
                  </Link>
                </p>
              </div>
            </li>
            <li className="list-unstyled">
              <Link
                className={`${active === "Journal"
                  ? "text-decoration-none activetext"
                  : `text-decoration-none ${isNightMode ? 'text-white' : 'text-black'}`
                  }`}
                to="/journals"
                onClick={(e) => handleClick(e)}
              >
                Journal
              </Link>
            </li>
          </ul>
        </div>
        <div className="col-lg-5 d-none d-lg-flex justify-content-end align-items-center text-end pe-5">

          {isNightMode ? <Sun style={{ width: '18px', height: '18px' }} className="m-2" onClick={handleNightModeToggle} /> : <Moon style={{ width: '18px', height: '18px' }} className="m-2" onClick={handleNightModeToggle} />}
          {/* <Search style={{ width: '18px', height: '18px' }} className="m-2" /> */}
          {/* <a style={{ color: isNightMode ? 'white' : 'black' }} href={link}><Lock style={{ width: '18px', height: '18px', }} className="m-2" /></a> */}
          <Lock style={{ width: '18px', height: '18px', color: isNightMode ? 'white' : 'black' }} className="m-2" />

          <Globe style={{ width: '18px', height: '18px' }} className="m-2 me-4" />



          {/* <UncontrolledDropdown style={{ listStyleType: 'none' }} tag='li' className={`dropdown-user nav-item `}>
            <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
              <Image src={avatar} alt="Avatar" className="mr-2" width={40} height={40} roundedCircle border="1px solid #ddd"
                onClick={handleProfileDropdownToggle}
                onMouseEnter={() => setShowProfileDropdown(true)}
                onMouseLeave={() => setShowProfileDropdown(false)}
              />
            </DropdownToggle>
            <DropdownMenu right className={`${isNightMode ? 'night-mode' : ''}`} style={{ width: '200px', marginTop: '13px' }}>
              <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/updateProfile'>
                <CreditCard size={19} className='me-3' />
                <span className='align-middle'
                >Edit Profile</span>
              </DropdownItem>
              <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/login'
              >
                <Server size={19} className='me-3' />
                <span className='align-middle'>Favourite</span>
              </DropdownItem>
              <DropdownItem className={`${isNightMode ? 'night-mode' : ''}`} tag={Link} to='/login'
              >
                <Power size={19} className='me-3' />
                <span className='align-middle'>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown> */}
        </div>
      </div>
    </div >
  );
};

export default Navbar;
