import React, { useState, useMemo, useEffect } from 'react';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import { toolbarPlugin } from '@react-pdf-viewer/toolbar';


// Import styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// import { defaultLayoutPlugin, sidebarPlugin } from '@react-pdf-viewer/default-layout';


function PdfViewer({URL}) {
    const [fileUrl, setFileUrl] = useState('https://cdn.britannica.com/21/182021-050-666DB6B1/book-cover-To-Kill-a-Mockingbird-many-1961.jpg');
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const defaultLayoutPluginInstance = defaultLayoutPlugin({
        downloadButton: {
            hideDownloadButton: true,
        },
    });

    const renderToolbar = (toolbarSlot) => {
        const { Download } = toolbarSlot;
        if (Download) {
          return <></>; // Returning an empty fragment to hide the Download button
        }
        return toolbarSlot;
      };

    console.log('url', URL);

    useEffect(() => {
        const hideDownloadButtons = () => {
          const downloadButton1 = document.querySelector('.rpv-core__minimal-button[aria-label="Download"]');
        const downloadButton2 = document.querySelector('.rpv-core__menu-item[data-testid="get-file__download-menu"]');
        const printButton1 = document.querySelector('.rpv-core__minimal-button[aria-label="Print"]');
        const printButton2 = document.querySelector('.rpv-core__menu-item[data-testid="print__menu"]');

    
          if (downloadButton1) {
            downloadButton1.style.display = 'none';
          }
    
          if (downloadButton2) {
            downloadButton2.style.display = 'none';
          }
          if (printButton1) {
            printButton1.style.display = 'none';
          }
          if(printButton2) {
            printButton2.style.display = 'none';

          }
        };
    
        const observer = new MutationObserver(hideDownloadButtons);
    
        // Observe changes to the body and its subtree
        observer.observe(document.body, { subtree: true, childList: true });
    
        // Clean up the observer when the component unmounts
        return () => {
          observer.disconnect();
        };
      }, []);

    return (
        <div>
            <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.4.120/pdf.worker.min.js">
                <Viewer
                    // fileUrl='https://nmdclibrary.ethical-digit.com/one.pdf'
                    fileUrl={`https://ed-nmdc-library.s3.amazonaws.com/pdf/${URL}`}
                    plugins={[
                        // Register plugins
                        defaultLayoutPluginInstance
                    ]}
                    // layoutPluginInstance={defaultLayoutPlugin({
                    //     renderToolbar, // Use the custom renderToolbar function to hide the download button
                    //   })}
                    defaultScale={SpecialZoomLevel.PageFit}
                />
            </Worker>
        </div>
    );
}

export default PdfViewer;
