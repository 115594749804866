import React from "react";
import Navbar from "./component/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./component/Home/Home";
import Footer from "./component/Footer/Footer";
import MmBooksRoute from "./component/MmBooksRoute/MmBooksRoute";
import EngBooksRoute from "./component/EngBooksRoute/EngBooksRoute";
import ResearchPapers from "./component/ResearchPaper/ResearchPapers";
import Journals from "./component/Journals/Journals";
import BookDetail from "./component/BookDetail/BookDetail";
import ResearchDetail from "./component/ResearchPaper/ResearchDetail";
import JournalDetail from "./component/Journals/JournalDetail";
import Login from "./component/LoginAndSignUp/Login";
import Signup from "./component/LoginAndSignUp/Signup";
import LoginWithNMDC from "./component/LoginAndSignUp/LoginWithNMDC";
import LoginTest from "./component/LoginTest/LoginTest";
import ProfileUpdate from "./component/ProfileUpdate/ProfileUpdate";
import JournalsDetailUpdate from "./component/Journals/JournalDetailUpdate";
import PopularAuthorsBooks from "./component/PopularAuthorsBooks/PopularAuthorsBooks";


const App = () => {
  return (
    <BrowserRouter>
    {/* <div style={{minHeight: '100vh'}}> */}
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/:id" element={<BookDetail />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logintest" element={<LoginTest />} />
        <Route path="/loginWithNMDC" element={<LoginWithNMDC />} />
        <Route path="/updateProfile" element={<ProfileUpdate />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/myanmar_books" element={<MmBooksRoute />} />
        <Route path="/myanmar_books/:id" element={<BookDetail />} />
        <Route path="/english_books" element={<EngBooksRoute />} />
        <Route path="/english_books/:id" element={<BookDetail />} />
        <Route path="/research_papers" element={<ResearchPapers />} />
        <Route path="/research_papers/123456789" element={<ResearchDetail />} />
        <Route path="/journals" element={<Journals />} />
        <Route path="/journals/:id" element={<JournalsDetailUpdate />} />
        <Route path="/popularAuthors/:id" element={<PopularAuthorsBooks />} />
        <Route path="/popularAuthors/:id/:id" element={<BookDetail />} />
      </Routes>
      <Footer />
      {/* </div> */}
    </BrowserRouter>
  );
};

export default App;
