import React, { useEffect, useState } from 'react'
import Avatar from 'react-avatar';
import { Grid, List, Search, Star } from 'react-feather';
import Rating from 'react-rating';

import Select from 'react-select';
import Pagination from 'react-bootstrap/Pagination';
import { useGlobalContext } from '../../context';

import AsyncSelect from 'react-select/async';
import { Link } from 'react-router-dom';

import './style.css'

import YearPicker from 'react-year-picker';

const EngBooksRoute = () => {
    const { isNightMode } = useGlobalContext()
    const [selectedOption, setSelectedOption] = useState('Data Descending');

    const [searchTerm, setSearchTerm] = useState('');
    const [publisher, setPublisher] = useState('');
    const [selectedAuthor, setSelectedAuthor] = useState('');
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedGenre, setSelectedGenre] = useState('');
    const [selectedLevel, setSelectedLevel] = useState('')
    const [selectedLanguage, setSelectedLanguage] = useState('');
    const [selectedRating, setSelectedRating] = useState('');

    const [currentPage, setCurrentPage] = useState(1);
    // const total = 100; // Replace with your total number of pages
    const maxPagesToShow = 7; // Maximum number of pages to show in the pagination UI

    const [perPage, setPerPage] = useState(20);

    const [isGrid, setIsGrid] = useState(true);
    const [engBooks, setEnglishBooks] = useState([]);
    const [foundresults, setFoundresults] = useState();
    const [loading, setLoading] = useState(false)

    const [allAuthors, setAllAuthors] = useState([])
    const [allCategories, setAllCategories] = useState([])
    const [subCategories, setSubCategories] = useState([])
    const [hasSubCategories, setHasSubCategories] = useState(false)
    const [curSubCates, setCurSubCates] = useState([])
    const [selectedCurSubCate, setSelectedCurSubCate] = useState('All')

    const [clear, setClear] = useState(false)

    const actualTotal = Math.ceil(foundresults / perPage); // Calculate actual total number of pages
    const total = Math.min(actualTotal, 100);

    const [selectedTitleOption, setSelectedTitleOption] = useState(null);



    useEffect(() => {
        setLoading(true)
        if(selectedCurSubCate !== 'All') {
            if (selectedAuthor) {
            fetch(`https://library-b.ethical-digit.com/authors/${selectedAuthor}?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedCurSubCate}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        } else {
            fetch(`https://library-b.ethical-digit.com/books?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&author=${selectedAuthor}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedCurSubCate}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        }
        }
        
    },[selectedCurSubCate])

    
  
// console.log(yearInputs)
    // useEffect(() => {
    //     // Loop through the matched elements
    //     for (var i = 0; i < yearInputs.length; i++) {
    //     // Set the value attribute to "2020"
    //     yearInputs[i].value = selectedYear;
    //     }
    // },[selectedYear])

    // useEffect(() => {
    //     subCategories?.map((each) => {
    //         if(each?.includes(selectedGenre)) {
    //             setHasSubCategories(true)
    //             setCurSubCates(subCategories?.filter((each) => each?.includes?.(selectedGenre)))
    //         } else {
    //             setHasSubCategories(false)
    //         }
    //     })
    // },[selectedGenre])

    const promiseOptions = (inputValue) =>
        new Promise((resolve) => {
            fetch(`https://library-b.ethical-digit.com/books/names?title=${inputValue}&type=english`)
                .then((res) => res.json())
                .then((data) => {
                    const options = data.bookNames.map((book) => ({
                        label: book.title,
                        value: book.title,
                        id: book._id,
                        photo: book.coverPhoto,
                        author: book.author,
                        rating: book.rate
                    }));
                    resolve(options);
                });
        });
    console.log(allCategories)
    console.log(subCategories)

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
        }
      };

    // function handleInputChange(inputValue) {
    //     setSelectedTitleOption({ label: inputValue, value: inputValue });
    //     // Call the promiseOptions function with the user input
    //     // to fetch the matching options from the server
    //     return promiseOptions(inputValue);
    // }

    const handleSearchFilter = () => {
        setLoading(true)
        fetch(`https://library-b.ethical-digit.com/books?type=english&title=${selectedTitleOption?.value}`)
            // fetch(`https://library-b.ethical-digit.com/books`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setEnglishBooks(res?.books)
                setFoundresults(res?.total_results)
                setLoading(false)
            })
            .catch(error => console.error(error));
    }


    useEffect(() => {
        setLoading(true)
        if (selectedAuthor) {
            fetch(`https://library-b.ethical-digit.com/authors/${selectedAuthor}?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedGenre}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        } else {
            fetch(`https://library-b.ethical-digit.com/books?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&author=${selectedAuthor}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedGenre}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        }
    }, [perPage, currentPage, clear, selectedOption])

    useEffect(() => {
        fetch(`https://library-b.ethical-digit.com/authors/names?type=english`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
                setAllAuthors(res?.authors)
                // setLoading(false)
            })
            .catch(error => console.error(error));
    }, [])

    useEffect(() => {
        fetch(`https://library-b.ethical-digit.com/books/categories?type=english`)
            .then(response => response.json())
            .then(res => {
                console.log(res)
                if(res?.categories){
                     const allCate = res?.categories?.filter((each) => !each?.includes("/"));
                    const allSubCate = res?.categories.filter((each) => each?.includes("/"))
                    // console.log(allCate);
                    // console.log(allSubCate);
                    setSubCategories(allSubCate);
                    setAllCategories(allCate);
                }
                   
            })
            .catch(error => console.error(error));
    }, [])


    const filtersApi = () => {
        setLoading(true)

       
        if (selectedGenre) {
            const foundSubCategory = subCategories?.some((each) => each?.includes(selectedGenre));
            
            if (foundSubCategory) {
              setHasSubCategories(true);
              setCurSubCates(subCategories?.filter((each) => each?.includes?.(selectedGenre)));
            } else {
              setHasSubCategories(false);
            }
          } else {
            setHasSubCategories(false);
          }

        if (selectedAuthor) {
            fetch(`https://library-b.ethical-digit.com/authors/${selectedAuthor}?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedGenre}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        } else {
            fetch(`https://library-b.ethical-digit.com/books?type=english&page=${currentPage - 1}&bookPerPage=${perPage}&title=${searchTerm}&author=${selectedAuthor}&rating=${selectedRating}&year=${selectedYear}&level=${selectedLevel}&category=${selectedGenre}&sort=${selectedOption}`)
                // fetch(`https://library-b.ethical-digit.com/books`)
                .then(response => response.json())
                .then(res => {
                    console.log(res)
                    setEnglishBooks(res?.books)
                    setFoundresults(res?.total_results)
                    setLoading(false)
                })
                .catch(error => console.error(error));
        }
    }

    // const goToDetial = () => {
    //     localStorage.setItem('currentScrollPos', window.scrollY);
    // }
    // useEffect(() => {
    //     const timeout = setTimeout(() => {
    //         const currentScrollPos = localStorage.getItem('currentScrollPos');
    //         if (currentScrollPos) {
    //             window.scrollTo(0, parseInt(currentScrollPos));
    //         }
    //     }, 100);
    //     return () => clearTimeout(timeout);
    // }, [])


    console.log(engBooks)

    const handlePerPageChange = (selectedOption) => {
        setPerPage(selectedOption.value);
        setCurrentPage(1);
        window.scrollTo(0, 0);

        // Do something with the selected number of items per page
    };

    const options = [
        { value: 10, label: '10 per page' },
        { value: 20, label: '20 per page' },
        { value: 50, label: '50 per page' },
        { value: 100, label: '100 per page' },
    ];

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
        console.log(pageNumber)
        // Do something with the selected page number
    };

    const renderPageItem = (pageNumber) => (
        <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageClick(pageNumber)}
        >
            {pageNumber}
        </Pagination.Item>
    );

    const renderEllipsisItem = () => (
        <Pagination.Ellipsis key="ellipsis" disabled />
    );

    const paginationItems = [];
    if (total <= maxPagesToShow) {
        for (let i = 1; i <= total; i++) {
            paginationItems.push(renderPageItem(i));
        }
    } else {
        const leftPagesToShow = Math.ceil(maxPagesToShow / 2) - 1;
        const rightPagesToShow = maxPagesToShow - leftPagesToShow - 1;
        let firstPageToShow = currentPage - leftPagesToShow;
        let lastPageToShow = currentPage + rightPagesToShow;

        if (firstPageToShow < 1) {
            lastPageToShow += Math.abs(firstPageToShow) + 1;
            firstPageToShow = 1;
        } else if (lastPageToShow > total) {
            firstPageToShow -= lastPageToShow - total;
            lastPageToShow = total;
        }

        if (firstPageToShow > 1) {
            paginationItems.push(renderPageItem(1));
            if (firstPageToShow > 2) {
                paginationItems.push(renderEllipsisItem());
            }
        }

        for (let i = firstPageToShow; i <= lastPageToShow; i++) {
            paginationItems.push(renderPageItem(i));
        }

        if (lastPageToShow < total) {
            if (lastPageToShow < total - 1) {
                paginationItems.push(renderEllipsisItem());
            }
            paginationItems.push(renderPageItem(total));
        }
    }

    const handlePublisher = (event) => {
        setPublisher(event.target.value)
    }

    const handleSearchChange = (event) => {
        localStorage.setItem('searchTerm', event.target.value);

        setSearchTerm(event.target.value);
    }

    const handleAuthorChange = (event) => {
        localStorage.setItem('filterAuthor', event.target.value);
        setSelectedAuthor(event.target.value);
    }

    const handleYearChange = (data) => {
        localStorage.setItem('filterYear', data);
        setSelectedYear(data);
    }

    const handleGenreChange = (event) => {
        localStorage.setItem('filterDenre', event.target.value);
        setSelectedGenre(event.target.value);
    }

    const handleLanguageChange = (event) => {
        setSelectedLanguage(event.target.value);
    }

    const handleRatingChange = (event) => {
        localStorage.setItem('filterRating', event.target.value);
        setSelectedRating(event.target.value);
    }

    const handleLevelChange = (event) => {
        localStorage.setItem('filterLevel', event.target.value);
        setSelectedLevel(event.target.value)
    }


    const clearFilter = () => {
        localStorage.setItem('searchTerm', '');
        setSearchTerm('');
        localStorage.setItem('filterYear', '');
        setSelectedYear('');
        localStorage.setItem('filterDenre', '');
        setSelectedGenre('');
        localStorage.setItem('filterRating', '');
        setSelectedRating('');
        localStorage.setItem('filterLevel', '');
        setSelectedLevel('')
        localStorage.setItem('filterAuthor', '');
        setSelectedAuthor('')
        setClear(!clear)
        setHasSubCategories(false)
    }

    // const filteredBooks = engBooks.filter((book) => {
    //     if (searchTerm !== '' && !book.title.toLowerCase().includes(searchTerm.toLowerCase())) {
    //         return false;
    //     }
    //     if (selectedAuthor !== '' && book.author !== selectedAuthor) {
    //         return false;
    //     }
    //     if (selectedYear !== '' && book?.publishAdt !== parseInt(selectedYear)) {
    //         return false;
    //     }
    //     if (selectedGenre !== '' && book.category !== selectedGenre) {
    //         return false;
    //     }
    //     if (selectedLanguage !== '' && book.language !== selectedLanguage) {
    //         return false;
    //     }
    //     if (selectedRating !== '' && book.rating < parseInt(selectedRating)) {
    //         return false;
    //     }
    //     return true;
    // });

    const authors = [...new Set(engBooks.map((book) => book.author?.name))];
    const years = ['', ...new Set(engBooks.map((book) => book?.publicationDate))];
    const genres = ['', ...new Set(engBooks.map((book) => book?.category))];
    const languages = ['', ...new Set(engBooks.map((book) => book.type))];
    const ratings = ['', 1, 2, 3, 4, 5];



    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    }

    useEffect(() => {
        document.title = "LMS | MM_Books";
    }, []);

    const handleGridClick = () => {
        setIsGrid(true);
        const booksContainer = document.querySelector('.books-container');
        if (booksContainer) {
            booksContainer.classList.remove('col-10');
            booksContainer.classList.add('col-5');
        }
    };

    const handleListClick = () => {
        setIsGrid(false);
        const booksContainer = document.querySelector('.books-container');
        if (booksContainer) {
            booksContainer.classList.remove('col-5');
            booksContainer.classList.add('col-10');
        }
    };

    return (
        <>
            {loading ? (
                <div style={{ width: '100vw', height: '100vh', backgroundSize: 'cover', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                    <img className="pt-4 mt-4" style={{ width: '20vw' }} src="https://cdn.dribbble.com/users/337865/screenshots/3209990/book-loader_v1.4_transp_800x600.gif" alt="" />
                </div>) : (
                <div style={{background: '#ECF0F3'}} className={`pt-2 mt-5 ${isNightMode ? 'nightHome' : ''}`}>
                    <div className="d-lg-flex justify-content-center gap-2 pt-5 engbookslistframe" style={{ width: '100vw' }}>
                        {/* <div className="col-2" style={{ border: '1px solid red', height: '500px' }}></div> */}
                        <div className="col-lg-8 col-12 left_home_template">
                            <div className='relative col-lg-11 m-auto d-flex align-items-center justify-content-center mb-3'>
                                <AsyncSelect
                                    cacheOptions
                                    defaultOptions
                                    loadOptions={promiseOptions}
                                    value={selectedTitleOption}
                                    onChange={setSelectedTitleOption}
                                    placeholder="Search for a book ..."
                                    onKeyDown={handleKeyDown}
                                    styles={{
                                        dropdownIndicator: (provided) => ({
                                            ...provided,
                                            // display: 'none',
                                        }),
                                        control: (provided) => ({
                                            ...provided,
                                            // width: '800px',
                                        }),
                                    }}
                                    className="search-bar"
                                    // getOptionLabel={(option) => option.label}
                                    // getOptionValue={(option) => option.value}
                                    formatOptionLabel={(option) => (
                                        <Link style={{ textDecoration: 'none' }} to={`/english_books/${option?.id}`}>
                                            <div className='d-flex align-items-top border-bottom' style={{ height: '60px' }}>
                                                <img className='me-3' style={{ height: '100%' }} src={`https://ed-nmdc-library.s3.amazonaws.com/pdf/images/${option?.photo}`} alt={option?.label} />

                                                <div className='col-6'>
                                                    {option?.label.charAt(0).toUpperCase() + option?.label.slice(1)}
                                                    <p style={{ color: '#83A8C3' }} className="m-0"> by {option?.author?.map(author => {
                                                        const nameParts = author.name.split(' ');
                                                        const capitalizedParts = nameParts.map(part => part.charAt(0).toUpperCase() + part.slice(1));
                                                        return capitalizedParts.join(' ');
                                                    }).join(", ")}</p>

                                                </div>
                                                <div className='flex-end col-3'>
                                                    <ul className='unstyled-list list-inline m-0'>
                                                        <Rating
                                                            emptySymbol={<Star size={20} fill='#babfc7' stroke='#babfc7' />}
                                                            fullSymbol={<Star size={20} fill='#FF9F43' stroke='#FF9F43' />}
                                                            fractions={2}
                                                            initialRating={option.rating}
                                                            // direction={isRtl ? 'rtl' : 'ltr'}
                                                            direction='ltr'
                                                        />
                                                    </ul></div>
                                            </div>
                                        </Link>
                                    )}
                                />
                                {/* <button onClick={handleSearchFilter} className='d-flex no-border justify-content-center align-items-center' style={{ border: '0px', width: '50px', height: '40px' }}>
                                    <Search />
                                </button> */}
                            </div>
                            {hasSubCategories && (
                                <div className='col-11 m-auto mb-2 d-flex justify-content-center subCateParent'>
                                    <span class={`badge rounded-pill ${selectedCurSubCate == 'All' ? 'bg-primary' : 'bg-light'} text-dark me-2 subCate`} style={{padding: '.5rem 1.5rem', fontSize: '1rem', cursor: 'pointer'}} onClick={() => {setSelectedCurSubCate('All'); filtersApi();}}>All</span>
                                {curSubCates?.map((each) => <span class={`badge rounded-pill ${selectedCurSubCate == each?.split('/')[1] ? 'bg-primary' : 'bg-light'} text-dark me-2 subCate`} style={{padding: '.5rem 1.5rem', fontSize: '1rem', cursor: 'pointer', textTransform: 'capitalize'}} onClick={() => {setSelectedCurSubCate(each?.split('/')[1]);}}>{each?.split('/')[1]}</span>)}
                            </div>)}

                            <div className='d-flex justify-content-between align-items-center pb-4'>
                                <h5 className='ms-4'>Found {foundresults} books in total</h5>
                                <div className='me-5 pe-1 filterDataDeviceSize'>
                                    {/* <label htmlFor="sort-options">Sort by:</label> */}
                                    <select style={{ width: '180px' }} id="sort-options" value={selectedOption} onChange={handleOptionChange}>
                                        <option style={{ height: "50px" }} value="createdAt">Filter Data</option>
                                        <option style={{ height: "50px" }} value="z_to_a">Title Descending</option>
                                        <option style={{ height: "50px" }} value="a_to_z">Title Ascending</option>
                                        <option style={{ height: "50px" }} value="descending">Year Descending</option>
                                        <option style={{ height: "50px" }} value="ascending">Year Ascending</option>
                                    </select>
                                    <Grid style={{ color: isGrid && '#0156f4' }} onClick={handleGridClick} className='ms-2' />
                                    <List style={{ color: !isGrid && '#0156f4' }} onClick={handleListClick} className='ms-2' />
                                    {/* <a class="view-type type-list" data-type="list"></a> */}
                                    {/* <i class="fa-solid fa-list"></i> */}
                                    {/* <a style={{ width: '50px', height: '50px' }} href="https://www.flaticon.com/free-icons/listing" title="listing icons"></a> */}
                                </div>
                            </div>
                            <div className={`${engBooks.length > 0 ? '' : 'engBooksBodymain'} d-md-flex flex-wrap p-1 justify-content-center gap-lg-5 py-lg-4`}>
                                {engBooks?.map((each, index) => (
                                    <div key={index} className={`books-container ${isGrid ? 'col-5' : 'col-10 mt-3 p-2'} mb-5 mb-lg-0 position-relative each_children_of_left_home_template`} style={{ border: '1px solid #0156f4', borderRadius: '.2rem', boxShadow: "-9px 6px 16px -6px rgba(45,56,80,0.75)", minHeight: '153.66px' }}>

                                        <div className="p-1" style={{ marginLeft: '6.5rem' }}>
                                            <h5 className="m-0" style={{
                                                whiteSpace: "nowrap",
                                                overflow: "hidden",
                                                textOverflow: "ellipsis",
                                                textTransform: "capitalize"
                                            }}>{each?.title}</h5>
                                            <p style={{ color: '#83A8C3' }} className="m-0">{each?.publicationDate}, by {each?.author?.length > 1 ? `${each?.author?.[0]?.name}, ...` : each?.author?.[0]?.name}</p>
                                            {/* <p style={{ color: '#83A8C3' }} className="m-0">{each?.publicationDate?.split("T")[0]}, by {each?.author ? each?.author.map(author => author.name).join(', ') : "Not known"}</p> */}
                                            <ul className='unstyled-list list-inline m-0'>
                                                <Rating
                                                    emptySymbol={<Star size={25} fill='#babfc7' stroke='#babfc7' />}
                                                    fullSymbol={<Star size={25} fill='#FF9F43' stroke='#FF9F43' />}
                                                    fractions={2}
                                                    initialRating={4}
                                                    // direction={isRtl ? 'rtl' : 'ltr'}
                                                    direction='ltr'
                                                />
                                            </ul>
                                            <p style={{ color: '#83A8C3' }} className="m-0">
                                                {each?.description.length > 55
                                                    ? each?.description.slice(0, 55) + "..."
                                                    : each?.description}
                                            </p>
                                            {/* <p style={{ color: '#83A8C3' }} className="m-0">A Nobel Prize-winning physicist explains what happened at the very beginning of the...</p> */}
                                        </div>
                                        <div style={{ position: 'absolute', bottom: '15px', left: '-15px' }}>
                                            <a href={`/english_books/${each?._id}`}>
                                                <img src={`https://ed-nmdc-library.s3.amazonaws.com/pdf/images/${each?.coverPhoto}` || 'https://images.penguinrandomhouse.com/cover/9780446310789'} alt="" style={{ borderRadius: '.2rem', border: '1px solid #0156f4', width: '118px', height: '162px' }} />
                                            </a>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            {/* pagination */}
                            <div className='pt-lg-3'>
                                <Pagination className='justify-content-center'>
                                    <Pagination.First onClick={() => handlePageClick(1)} />
                                    <Pagination.Prev
                                        onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                                    />
                                    {paginationItems}
                                    <Pagination.Next
                                        onClick={() => handlePageClick(Math.min(currentPage + 1, total))}
                                    />
                                    <Pagination.Last onClick={() => handlePageClick(total)} />
                                </Pagination>
                            </div>
                            <div className='d-flex justify-content-between mb-2' style={{ width: '95%', height: 'auto' }}>
                                <h5>Books per page:</h5>
                                <Select
                                    value={{ value: perPage, label: `${perPage} per page` }}
                                    onChange={handlePerPageChange}
                                    options={options}
                                    menuPlacement="top"
                                    className={`${isNightMode && 'text-black'}`}
                                />
                            </div>
                        </div>
                        <div className="col-lg-3 col-12 ps-2 py-2 ps-4 ps-lg-0 " style={{ height: 'auto' }}>
                            <div className='bookfilterfixedDiv'>
                                <h4>BOOK FILTER</h4>

                                {/* <label className='pb-2'>
                                    Publisher: <br />
                                    <input style={{ width: '300px', padding: '.3rem .2rem', borderRadius: '5px', border: '1px solid gray' }} type="text" value={publisher} onChange={handlePublisher} />
                                </label><br /> */}

                                <label className='pb-2'>
                                    Filter by author: <br />
                                    <select style={{ width: '300px', padding: '.4rem .2rem', borderRadius: '5px', border: '1px solid gray' }} value={selectedAuthor} onChange={handleAuthorChange}>
                                        <option value=''>All</option>
                                        {allAuthors?.map((author, index) => (
                                            <option key={index} value={author?._id}>{author?.name.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}</option>
                                        ))}
                                    </select>
                                </label><br />
                                <label className='pb-2'>
                                    Filter by Category: <br />
                                    <select style={{ width: '300px', padding: '.4rem .2rem', borderRadius: '5px', border: '1px solid gray' }} value={selectedGenre} onChange={handleGenreChange}>
                                        <option value=''>All</option>
                                        {allCategories?.map((genre, index) => (
                                            <option key={index} value={genre}>{genre === '' ? 'All' : genre.charAt(0).toUpperCase() + genre.slice(1)}</option>
                                        ))}
                                    </select>
                                </label><br />
                                <label className='pb-2' style={{ paddingRight: '20px' }}>
                                    Filter by year: <br />
                                    {/* <select style={{ width: '140px', padding: '.4rem .2rem', borderRadius: '5px', border: '1px solid gray' }} value={selectedYear} onChange={handleYearChange}>
                                        {years.map((year, index) => (
                                            <option key={index} value={year}>{year === '' ? 'All' : year}</option>
                                        ))}
                                    </select> */}
                                     <YearPicker
                                        name='publication'
                                        className='col-12'
                                        value={selectedYear}
                                        onChange={handleYearChange}
                                        required
                                    />
                                    {/* <input style={{ width: '140px', padding: '.3rem .2rem', borderRadius: '5px', border: '1px solid gray' }} type="text" value={selectedYear} onChange={handleYearChange} /> */}

                                </label>
                                <label className='pb-2'>
                                    Filter by rating: <br />
                                    <select style={{ width: '140px', padding: '.4rem .2rem', borderRadius: '5px', border: '1px solid gray' }} value={selectedRating} onChange={handleRatingChange}>
                                        {ratings.map((rating, index) => (
                                            <option key={index} value={rating}>{rating === '' ? 'All' : rating}</option>
                                        ))}
                                    </select>
                                </label>
                                <label className='pb-2'>
                                    Filter by Level: <br />
                                    <select style={{ width: '300px', padding: '.4rem .2rem', borderRadius: '5px', border: '1px solid gray' }} value={selectedLevel} onChange={handleLevelChange}>
                                        <option value=''>All</option>
                                        <option key="Basic" value='basic'>Basic</option>
                                        <option key="Intermediate" value='intermediate'>Intermediate</option>
                                        <option key="Advance" value='advance'>Advance</option>

                                    </select>
                                </label><br />
                                <button className='rounded mt-3' style={{ padding: '.3rem .8rem' }} onClick={filtersApi}>Search</button>
                                <button className='rounded mt-3 ms-3' style={{ padding: '.3rem .8rem' }} onClick={clearFilter}>Clear</button>

                            </div>
                        </div>
                    </div>
                </div >
            )}
        </>

    )
}

export default EngBooksRoute