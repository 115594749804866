import React from "react";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
// import { FaEnvelopeSquare } from "react-icons/fa";
import { Lock, Mail } from "react-feather";
import { FaFacebook, FaGoogle, FaTwitter } from "react-icons/fa";

const Signup = () => {
    return (
        // <div style={{
        //     minHeight: '100vh', width: '100vw', backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://i.ytimg.com/vi/91fghY5XvoU/maxresdefault.jpg)",
        //     backgroundSize: 'cover'
        // }}>
        //     <Container className="mt-5 pt-5" >
        //         <Row className="justify-content-md-center">
        //             <Col xs={12} sm={8} md={6} style={{ boxShadow: '0px 0px 10px #0D6EFD', background: 'rgb(247,247,248)', borderRadius: '5px' }} className='p-5 py-3'>
        //                 <Form>
        //                     <h2>Sign Up</h2>
        //                     <Form.Group controlId="formBasicName" className='pt-2'>
        //                         <Form.Label>Name</Form.Label>
        //                         <Form.Control type="text" placeholder="Enter your name" />
        //                     </Form.Group>
        //                     <Form.Group controlId="formBasicEmail" className='pt-2'>
        //                         <Form.Label>Email address</Form.Label>
        //                         <div className="input-group">
        //                             <Form.Control type="email" placeholder="Enter email" />
        //                             <div className="input-group-append">
        //                                 <span className="input-group-text">
        //                                     <Mail />
        //                                 </span>
        //                             </div>
        //                         </div>
        //                     </Form.Group>
        //                     <Form.Group controlId="formBasicPassword" className='pt-2'>
        //                         <Form.Label>Password</Form.Label>
        //                         <div className="input-group">
        //                             <Form.Control type="password" placeholder="Password" />
        //                             <div className="input-group-append">
        //                                 <span className="input-group-text">
        //                                     <Lock />
        //                                 </span>
        //                             </div>
        //                         </div>
        //                     </Form.Group>
        //                     <Form.Group controlId="formBasicPassword" className='pt-2'>
        //                         <Form.Label>Comfirm Password</Form.Label>
        //                         <div className="input-group">
        //                             <Form.Control type="password" placeholder="Comfirm Password" />
        //                             <div className="input-group-append">
        //                                 <span className="input-group-text">
        //                                     <Lock />
        //                                 </span>
        //                             </div>
        //                         </div>
        //                     </Form.Group>
        //                     <Button className='col-12 mt-3' variant="primary" type="submit" block>
        //                         Sign Up
        //                     </Button>
        //                     <div className="text-center  pt-3">
        //                         <span>Or Sign Up with</span>
        //                     </div>

        //                     <div className="text-center mt-3 mb-3">
        //                         <Button className='me-1' variant="danger" type="button">
        //                             <FaGoogle />
        //                         </Button>
        //                         <Button className='me-1' variant="primary" type="button">
        //                             <FaFacebook />
        //                         </Button>
        //                         <Button className='me-1' variant="info" type="button">
        //                             <FaTwitter />
        //                         </Button>
        //                     </div>

        //                     <Form.Text className="text-center mt-3 d-flex justify-content-center">
        //                         Already have an account? <a href="/login">Log in</a>
        //                     </Form.Text>
        //                 </Form>
        //             </Col>
        //         </Row>
        //     </Container>
        // </div>
        <div className="containeroneForDevice" id="a-container">
            <form className="formSignIn" id="a-form" method action>
                <h2 className="formSignIn_title title">Create Account</h2>
                <div className="formSignIn__icons">
                    <div className="text-center">
                        <Button className='me-1' variant="danger" type="button">
                            <FaGoogle />
                        </Button>
                        <Button className='me-1' variant="primary" type="button">
                            <FaFacebook />
                        </Button>
                        <Button className='me-1' variant="info" type="button">
                            <FaTwitter />
                        </Button>
                    </div>
                </div><span className="formSignIn__span">or use email for registration</span>
                <Form.Group controlId="formBasicName" className='pt-2'>
                    <Form.Control className='formSignIn__input' type="text" placeholder="Enter your name" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail" className='pt-2'>
                    <div className="input-group">
                        <Form.Control className='formSignIn__input' type="email" placeholder="Email" />
                    </div>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className='pt-2'>
                    <div className="input-group">
                        <Form.Control className='formSignIn__input' type="password" placeholder="Password" />
                    </div>
                </Form.Group>
                <Form.Group controlId="formBasicPassword" className='pt-2'>
                    <div className="input-group">
                        <Form.Control className='formSignIn__input' type="password" placeholder="Comfirm Password" />
                    </div>
                </Form.Group>
                {/* <input className="formSignIn__input" type="text" placeholder="Name" />
                        <input className="formSignIn__input" type="text" placeholder="Email" />
                        <input className="formSignIn__input" type="password" placeholder="Password" /> */}
                <button className="formSignIn__button buttonSignIn submit">SIGN UP</button>
                <Form.Text className="text-center mt-3 d-flex justify-content-center">
                    Already have an account? <a href="/login">Log in</a>
                </Form.Text>
            </form>
        </div>
    );
};

export default Signup;
