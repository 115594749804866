import React, { useEffect } from 'react';
import { useState } from 'react';
import { Container, Row, Col, Card, Button, Pagination } from 'react-bootstrap';
import { useGlobalContext } from '../../context';
import './style.css';

const ResearchPapers = () => {
    const { isNightMode } = useGlobalContext();
    // Sample data for research papers
    const researchPapers = [
        {
            id: 1,
            title: 'The Impact of Climate Change on Coastal Communities',
            author: 'Sarah Johnson',
            publicationDate: 'March 2022',
            description: 'This research paper examines the potential effects of climate change on coastal communities, including sea level rise, storm surges, and erosion.'
        },
        {
            id: 2,
            title: 'The Ethics of Autonomous Vehicles',
            author: 'Michael Lee',
            publicationDate: 'April 2022',
            description: 'This paper discusses the ethical implications of using autonomous vehicles, including issues of safety, privacy, and responsibility.'
        },
        {
            id: 3,
            title: 'The Benefits and Challenges of Remote Work',
            author: 'Emily Chen',
            publicationDate: 'May 2022',
            description: 'This research paper examines the advantages and disadvantages of remote work, including its impact on productivity, work-life balance, and collaboration.'
        },
        {
            id: 4,
            title: 'The Role of Artificial Intelligence in Education',
            author: 'David Kim',
            publicationDate: 'June 2022',
            description: 'This paper explores the potential benefits and challenges of using artificial intelligence in education, including its impact on student learning, assessment, and equity.'
        },
        {
            id: 5,
            title: 'The Psychology of Decision Making',
            author: 'Maria Rodriguez',
            publicationDate: 'July 2022',
            description: 'This research paper examines the cognitive and social factors that influence decision making, including heuristics, biases, and group dynamics.'
        },
        {
            id: 6,
            title: 'The Impact of Social Media on Political Discourse',
            author: 'Jonathan Lee',
            publicationDate: 'August 2022',
            description: 'This paper analyzes the role of social media in shaping political discourse, including its impact on political polarization, misinformation, and civic engagement.'
        },
        {
            id: 7,
            title: 'The Science of Sleep',
            author: 'Sophia Nguyen',
            publicationDate: 'September 2022',
            description: 'This research paper explores the neurobiological and behavioral aspects of sleep, including its function, regulation, and disorders.'
        },
        {
            id: 8,
            title: 'The Future of Work in a Post-Pandemic World',
            author: 'Adam Johnson',
            publicationDate: 'October 2022',
            description: 'This paper discusses the long-term changes and challenges in the workplace resulting from the COVID-19 pandemic, including remote work, automation, and resilience.'
        },
        {
            id: 9,
            title: 'The History and Future of Cryptography',
            author: 'Katherine Lee',
            publicationDate: 'November 2022',
            description: 'This research paper traces the evolution of cryptography from ancient times to modern encryption methods, including its role in security, privacy, and digital trust.'
        },
        {
            id: 10,
            title: 'The Social and Economic Impacts of Aging',
            author: 'Linda Wang',
            publicationDate: 'December 2022',
            description: 'This paper examines the demographic, social, and economic consequences of an aging population, including its effects on health care, retirement, and intergenerational equity.'
        },
        {
            id: 11,
            title: 'The Effects of Social Media on Mental Health',
            author: 'John Smith',
            publicationDate: 'January 2022',
            description: 'This research paper explores the relationship between social media use and mental health, including the impact on anxiety, depression, and self-esteem.'
        },
        {
            id: 12,
            title: 'The Future of Artificial Intelligence in Healthcare',
            author: 'Jane Doe',
            publicationDate: 'February 2022',
            description: 'This paper examines the potential benefits and risks of using artificial intelligence in healthcare, including its impact on patient outcomes, costs, and privacy.'
        },
        {
            id: 13,
            title: 'The Impact of Climate Change on Global Food Systems',
            author: 'David Lee',
            publicationDate: 'March 2022',
            description: 'This research paper discusses the effects of climate change on agriculture and food production, including potential solutions to address the impact on food security and supply chains.'
        },
        {
            id: 14,
            title: 'The Role of Education in Reducing Income Inequality',
            author: 'Rachel Lee',
            publicationDate: 'April 2022',
            description: 'This paper explores the relationship between education and income inequality, including the impact of access to education and policies that promote educational equity.'
        },
        {
            id: 15,
            title: 'The Ethics of Gene Editing in Humans',
            author: 'Michael Johnson',
            publicationDate: 'May 2022',
            description: 'This research paper examines the ethical considerations of gene editing technology in humans, including the potential for unintended consequences and the need for regulation.'
        },
        {
            id: 16,
            title: 'The Impacts of Automation on the Labor Market',
            author: 'Jessica Brown',
            publicationDate: 'June 2022',
            description: 'This paper discusses the potential impact of automation on the job market, including the effects on employment rates, wages, and job security.'
        },
        {
            id: 17,
            title: 'The Role of Social Support in Mental Health Recovery',
            author: 'Linda Jackson',
            publicationDate: 'July 2022',
            description: 'This research paper examines the importance of social support in mental health recovery, including the benefits of social networks and community resources.'
        },
        {
            id: 18,
            title: 'The Effects of Gender Bias in the Workplace',
            author: 'Sarah Lee',
            publicationDate: 'August 2022',
            description: 'This paper explores the impact of gender bias in the workplace, including the effects on hiring practices, promotions, and work-life balance.'
        },
        {
            id: 19,
            title: 'The Potential of Virtual Reality in Education',
            author: 'Andrew Kim',
            publicationDate: 'September 2022',
            description: 'This research paper examines the potential uses of virtual reality technology in education, including its impact on student engagement, learning outcomes, and accessibility.'
        },
        {
            id: 20,
            title: 'The Effects of Climate Change on Public Health',
            author: 'Maria Rodriguez',
            publicationDate: 'October 2022',
            description: 'This paper discusses the effects of climate change on public health, including the impact on air quality, infectious diseases, and mental health.'
        },
    ];

    const [currentPage, setCurrentPage] = useState(1);
    const total = 100; // Replace with your total number of pages
    const maxPagesToShow = 7; // Maximum number of pages to show in the pagination UI

    const [perPage, setPerPage] = useState(10);

    const handlePageClick = (pageNumber) => {
        setCurrentPage(pageNumber);
        window.scrollTo(0, 0);
        console.log(pageNumber)
        // Do something with the selected page number
    };

    const renderPageItem = (pageNumber) => (
        <Pagination.Item
            key={pageNumber}
            active={pageNumber === currentPage}
            onClick={() => handlePageClick(pageNumber)}
        >
            {pageNumber}
        </Pagination.Item>
    );

    const renderEllipsisItem = () => (
        <Pagination.Ellipsis key="ellipsis" disabled />
    );

    const paginationItems = [];
    if (total <= maxPagesToShow) {
        for (let i = 1; i <= total; i++) {
            paginationItems.push(renderPageItem(i));
        }
    } else {
        const leftPagesToShow = Math.ceil(maxPagesToShow / 2) - 1;
        const rightPagesToShow = maxPagesToShow - leftPagesToShow - 1;
        let firstPageToShow = currentPage - leftPagesToShow;
        let lastPageToShow = currentPage + rightPagesToShow;

        if (firstPageToShow < 1) {
            lastPageToShow += Math.abs(firstPageToShow) + 1;
            firstPageToShow = 1;
        } else if (lastPageToShow > total) {
            firstPageToShow -= lastPageToShow - total;
            lastPageToShow = total;
        }

        if (firstPageToShow > 1) {
            paginationItems.push(renderPageItem(1));
            if (firstPageToShow > 2) {
                paginationItems.push(renderEllipsisItem());
            }
        }

        for (let i = firstPageToShow; i <= lastPageToShow; i++) {
            paginationItems.push(renderPageItem(i));
        }

        if (lastPageToShow < total) {
            if (lastPageToShow < total - 1) {
                paginationItems.push(renderEllipsisItem());
            }
            paginationItems.push(renderPageItem(total));
        }
    }

    useEffect(() => {
        // This will run when the page first loads and whenever the title changes
        document.title = "LMS | Research";
    }, []);

    return (
        <div className={` ${isNightMode ? 'nightHome' : ''}`}>
            <Container className={`research-papers pt-5 mt-4`}>
                <h1 className="text-center my-4">Research Papers</h1>
                <Row>
                    {researchPapers.map((researchPaper) => (
                        <Col key={researchPaper.id} xs={12} md={6} lg={4} className="my-3">
                            <Card className="h-100">
                                <Card.Body className={`${isNightMode ? 'researchEachPaper' : ''}`}>
                                    <Card.Title className={`${isNightMode ? 'researchEachPaperTitle' : ''}`} style={{ color: '#0156f4' }}>{researchPaper.title}</Card.Title>
                                    <Card.Subtitle className="mb-0 text-muted">by {researchPaper.author}</Card.Subtitle>
                                    <Card.Text className="text-muted mb-0">{researchPaper.publicationDate}</Card.Text>
                                    <Card.Text>{researchPaper.description}</Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        // <Col key={researchPaper.id} xs={12} md={6} lg={4} className="my-3">
                        //     <Card className="h-100">
                        //         <div className="position-relative">
                        //             <Card.Img variant="top" src='https://contenthub-static.grammarly.com/blog/wp-content/uploads/2022/02/Effective-Research-Paper-760x406.jpg' alt={researchPaper.title} />
                        //             <div className="overlay position-absolute w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                        //                 <h3 className="text-white mb-0">{researchPaper.title}</h3>
                        //                 <small className="text-white">{researchPaper.author}</small>
                        //             </div>
                        //         </div>
                        //         <Card.Body className="d-flex flex-column justify-content-between">
                        //             <div>
                        //                 <Card.Text className="text-muted">{researchPaper.publicationDate}</Card.Text>
                        //                 <Card.Text>{researchPaper.description}</Card.Text>
                        //             </div>
                        //             <div className="mt-3 d-flex justify-content-between align-items-center">
                        //                 <Button variant="outline-primary">Read More</Button>
                        //                 <div className="d-flex align-items-center">
                        //                     <i className="bi bi-heart-fill me-2"></i>
                        //                     <span>{researchPaper.likes}</span>
                        //                 </div>
                        //             </div>
                        //         </Card.Body>
                        //     </Card>
                        // </Col>
                    ))}
                </Row>
                <div className='pt-3'>
                    <Pagination className='justify-content-center'>
                        <Pagination.First onClick={() => handlePageClick(1)} />
                        <Pagination.Prev
                            onClick={() => handlePageClick(Math.max(currentPage - 1, 1))}
                        />
                        {paginationItems}
                        <Pagination.Next
                            onClick={() => handlePageClick(Math.min(currentPage + 1, total))}
                        />
                        <Pagination.Last onClick={() => handlePageClick(total)} />
                    </Pagination>
                </div>
            </Container>
        </div>
    );
};

export default ResearchPapers;
