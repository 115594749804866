import React, { useEffect } from 'react'
import { useState } from 'react';
import { FaFacebook, FaGoogle, FaTwitter } from 'react-icons/fa';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';

import './style.css'
import { Lock, Mail } from 'react-feather';

const LoginTest = () => {
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [studentId, setStudentId] = useState('');
    const [password, setPassword] = useState('');
    const [isActive, setIsActive] = useState(false);

    const handleEmailLogin = () => {
        // handle email login logic
    }

    const handleNMDCLogin = () => {
        // handle NMDC login logic
    }

    const activateButton = (buttonType) => {
        setIsActive(buttonType);
    }


    const handleGoogleLogin = async () => {
        // const provider = new firebase.auth.GoogleAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleFacebookLogin = async () => {
        // const provider = new firebase.auth.FacebookAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleTwitterLogin = async () => {
        // const provider = new firebase.auth.TwitterAuthProvider();
        try {
            // await firebase.auth().signInWithPopup(provider);
            // Handle successful login
        } catch (error) {
            // Handle login error
        }
    };

    const handleForgotPassword = () => {
        // firebase.auth().sendPasswordResetEmail(email)
        //     .then(() => {
        //         // Handle successful password reset email send
        //     })
        //     .catch((error) => {
        //         // Handle password reset email send error
        //     });
    };

    useEffect(() => {
        let switchCtn = document.querySelector("#switch-cnt");
        let switchC1 = document.querySelector("#switch-c1");
        let switchC2 = document.querySelector("#switch-c2");
        let switchCircle = document.querySelectorAll(".switch__circle");
        let switchBtn = document.querySelectorAll(".switch-btn");
        let aContainer = document.querySelector("#a-container");
        let bContainer = document.querySelector("#b-container");
        let allButtons = document.querySelectorAll(".submit");

        let getButtons = (e) => e.preventDefault()

        let changeForm = (e) => {

            switchCtn.classList.add("is-gx");
            setTimeout(function () {
                switchCtn.classList.remove("is-gx");
            }, 1500)

            switchCtn.classList.toggle("is-txr");
            switchCircle[0].classList.toggle("is-txr");
            switchCircle[1].classList.toggle("is-txr");

            switchC1.classList.toggle("is-hidden");
            switchC2.classList.toggle("is-hidden");
            aContainer.classList.toggle("is-txl");
            bContainer.classList.toggle("is-txl");
            bContainer.classList.toggle("is-z200");
        }

        let mainF = (e) => {
            for (var i = 0; i < allButtons.length; i++)
                allButtons[i].addEventListener("click", getButtons);
            for (var i = 0; i < switchBtn.length; i++)
                switchBtn[i].addEventListener("click", changeForm)
        }

        mainF();

        return () => {
            for (var i = 0; i < allButtons.length; i++)
                allButtons[i].removeEventListener("click", getButtons);
            for (var i = 0; i < switchBtn.length; i++)
                switchBtn[i].removeEventListener("click", changeForm)
        }

    }, []);

    return (
        <div className='parent pt-5 mt-5'>
            <div className="mainSingIN">
                <div className="containerone a-containerone" id="a-container">
                    <form className="formSignIn" id="a-form" method action>
                        <h2 className="formSignIn_title title">Create Account</h2>
                        <div className="formSignIn__icons">
                            <div className="text-center">
                                <Button className='me-1' variant="danger" type="button">
                                    <FaGoogle />
                                </Button>
                                <Button className='me-1' variant="primary" type="button">
                                    <FaFacebook />
                                </Button>
                                <Button className='me-1' variant="info" type="button">
                                    <FaTwitter />
                                </Button>
                            </div>
                        </div><span className="formSignIn__span">or use email for registration</span>
                        <Form.Group controlId="formBasicName" className='pt-2'>
                            <Form.Control className='formSignIn__input' type="text" placeholder="Enter your name" />
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail" className='pt-2'>
                            <div className="input-group">
                                <Form.Control className='formSignIn__input' type="email" placeholder="Email" />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className='pt-2'>
                            <div className="input-group">
                                <Form.Control className='formSignIn__input' type="password" placeholder="Password" />
                            </div>
                        </Form.Group>
                        <Form.Group controlId="formBasicPassword" className='pt-2'>
                            <div className="input-group">
                                <Form.Control className='formSignIn__input' type="password" placeholder="Comfirm Password" />
                            </div>
                        </Form.Group>
                        {/* <input className="formSignIn__input" type="text" placeholder="Name" />
                        <input className="formSignIn__input" type="text" placeholder="Email" />
                        <input className="formSignIn__input" type="password" placeholder="Password" /> */}
                        <button className="formSignIn__button buttonSignIn submit">SIGN UP</button>
                    </form>
                </div>
                <div className="containers b-containers" id="b-container">

                    <form className="formSignIn" id="b-form" method action>
                        <div className='d-flex gap-1'>
                            <Button
                                className={`firstLogin`}
                                variant={`${!isActive ? 'primary' : 'outline-primary'}`}
                                onClick={() => {
                                    activateButton(false);
                                }}
                                block>
                                Login Form
                            </Button>
                            <Button
                                className={`secondLogin`}
                                variant={`${isActive ? 'primary' : 'outline-primary'}`}
                                onClick={() => {
                                    activateButton(true);
                                }}
                                block>
                                Sign in with NMDC
                            </Button>
                        </div>
                        <h2 className="formSignIn_title title">{isActive ? "Login With NMDC" : "Login Form"}</h2>

                        <div className="formSignIn__icons">
                            <div className="text-center">
                                <Button className='me-1' variant="danger" type="button" onClick={handleGoogleLogin}>
                                    <FaGoogle />
                                </Button>
                                <Button className='me-1' variant="primary" type="button" onClick={handleFacebookLogin}>
                                    <FaFacebook />
                                </Button>
                                <Button className='me-1' variant="info" type="button" onClick={handleTwitterLogin}>
                                    <FaTwitter />
                                </Button>
                            </div>
                        </div>
                        <span className="formSignIn__span">or use your email account</span>
                        {isActive ? (
                            <>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className='formSignIn__input' type="phone" placeholder="Phone" value={phone} onChange={(e) => setPhone(e.target.value)} />

                                </Form.Group>
                                <Form.Group controlId="formBasicEmail">
                                    <Form.Control className='formSignIn__input' type="number" placeholder="StudentId" value={studentId} onChange={(e) => setStudentId(e.target.value)} />

                                </Form.Group>
                            </>
                        ) : (
                            <Form.Group controlId="formBasicEmail">
                                <Form.Control className='formSignIn__input' type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />

                            </Form.Group>
                        )}

                        <Form.Group controlId="formBasicPassword" className='pt-2'>
                            <Form.Control className='formSignIn__input' type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />

                            <Form.Text className="text-right d-flex justify-content-end">
                                <a href="#" onClick={handleForgotPassword}>Forgot password?</a>
                            </Form.Text>
                        </Form.Group>
                        {/* <input className="formSignIn__input" type="text" placeholder="Email" />
                        <input className="formSignIn__input" type="password" placeholder="Password" /><a className="formSignIn__link">Forgot your password?</a> */}

                        {/* <button className="formSignIn__button buttonSignIn submit">SIGN IN</button> */}
                        {isActive ? (
                            <Button style={{ borderRadius: '25px' }} className='col-4 mt-3 formSignIn__button buttonSignIn ' variant="primary" type="submit" onClick={handleEmailLogin} block>
                                Sign in with NMDC
                            </Button>
                        ) : (
                            <Button style={{ borderRadius: '25px' }} className='col-4 mt-3 formSignIn__button buttonSignIn' variant="primary" type="submit" onClick={handleEmailLogin} block>
                                Sign in with email
                            </Button>
                        )}
                    </form>
                </div>
                <div className="switch" id="switch-cnt">
                    <div className="switch__circle" />
                    <div className="switch__circle switch__circle--t" />
                    <div className="switch__container" id="switch-c1">
                        <h2 className="switch__title title">Welcome Back !</h2>
                        <p className="switch__description description">To keep connected with us please login with your personal info</p>
                        <button className="switch__button buttonSignIn switch-btn">SIGN IN</button>
                    </div>
                    <div className="switch__container is-hidden" id="switch-c2">
                        <h2 className="switch__title title">Hello Friend !</h2>
                        <p className="switch__description description">Enter your personal details and start journey with us</p>
                        <button className="switch__button buttonSignIn switch-btn">SIGN UP</button>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default LoginTest